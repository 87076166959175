import { AppConfig, UserSession, showConnect, openContractCall } from '@stacks/connect';
import { STACKS_MAINNET, STACKS_TESTNET } from '@stacks/network';
import { bufferCV, addressToString, Cl, deserializePrincipal, standardPrincipalCVFromAddress, intCV, uintCV, PostConditionMode, stringAsciiCV, principalCV, standardPrincipalCV, FungibleConditionCode, makeContractCall, broadcastTransaction, fetchCallReadOnlyFunction, READONLY_FUNCTION_CALL_PATH, cvToJSON, Pc } from '@stacks/transactions';
import ColorThief from 'colorthief';
import { createClient } from '@supabase/supabase-js';
import { Router } from './router.js'; 
import './styles.css'; 
import './claim.css'; 
import WalletDropdown from './walletDropdown.js';
import { musicPlayer, musicData } from './musicConfig.js';


import track1Image from './audio/Josie_image.png';
import track1Audio from './audio/Josie_Field_RADIO_SILENCE.mp3'
import track2Image from './audio/Ard_image.png';
import track2Audio from './audio/Ard_Matthews_YOU_MAKE_IT_ALRIGHT.mp3';
import track3Image from './audio/Arno_image.png';
import track3Audio from './audio/Arno_Carstens_MORTALS.mp3';
import track4Image from './audio/Kahn_image.png';
import track4Audio from './audio/Kahn_Morbee_BUZZ_HEAD.mp3';
import track5Image from './audio/Laurie_image.png';
import track5Audio from './audio/Laurie_Levine_GREY.mp3';
import track6Image from './audio/Jacques_image2.png';
import track6Audio from './audio/Jacques_Moolman_EXILE.mp3';
import track7Image from './audio/Evert_Image.png';
import track7Audio from './audio/Evert_Snyman_I_SHOULDNT_BREAK.mp3';
import track8Image from './audio/Xander_image.png';
import track8Audio from './audio/Xander_BBQ_TO_BRAAI.mp3';
import albumImage from '/audio/Album_image.png';
import gatedLogo from '/gated-logo2.png';
import favicon from './gated-logo2.png';  
document.getElementById('album-art').src = albumImage;
const link = document.querySelector("link[rel~='icon']");
if (link) {
    link.href = favicon;
}


const container = document.getElementById('textReveal');

export class ProfilePhoto {
    constructor() {
        this.outerContainer = document.getElementById('profileOuterContainer');
        this.innerContainer = document.getElementById('profileInnerContainer');
        this.photo = document.getElementById('profilePhoto');
        this.tipText = document.getElementById('tipText');
        this.clickSound = document.getElementById('clickSound');

        // Debug logging to help identify missing elements
        if (!this.outerContainer) console.error('profileOuterContainer not found');
        if (!this.innerContainer) console.error('profileInnerContainer not found');
        if (!this.photo) console.error('profilePhoto not found');
        if (!this.tipText) console.error('tipText not found');
        if (!this.clickSound) console.error('clickSound not found');

        this.centerX = window.innerWidth / 2;
        this.centerY = window.innerHeight / 2;
        this.maxDistance = 20;
        this.lastClickTime = 0;
        this.consecutiveClicks = 0;

        this.longPressTimer = null;
        this.longPressDuration = 500; // ms
        this.nfts = [];
        this.currentNftIndex = 0;

        // Only add event listeners if elements exist
        if (this.innerContainer) {
            this.innerContainer.addEventListener('mousedown', e => this.onMouseDown(e));
            this.innerContainer.addEventListener('mouseup', () => this.onMouseUp());
            this.innerContainer.addEventListener('mouseleave', () => this.onMouseUp());
            this.innerContainer.addEventListener('click', e => this.onClick(e));
        }

        if (this.tipText) {
            this.tipText.addEventListener('click', e => this.onTipClick(e));
        }

        // Global event listeners
        window.addEventListener('mousemove', e => this.onMouseMove(e));
        window.addEventListener('resize', () => this.onResize());

        // User session check
        try {
            const userData = userSession.loadUserData();
            if (userData?.profile?.stxAddress?.testnet) {
                this.checkForNFTBackgroundTestnet();
            }
        } catch (error) {
            console.error('Error loading user data:', error);
        }
    }

    onMouseDown(e) {
        this.longPressTimer = setTimeout(() => this.onLongPress(e), this.longPressDuration);
    }

    onMouseUp() {
        if (this.longPressTimer) {
            clearTimeout(this.longPressTimer);
        }
    }


    async checkForNFTBackgroundMainnet() {
        const userData = userSession.loadUserData();
        const userAddress = userData.profile.stxAddress.mainnet;
        const stxAddress = userAddress;
        const maxAttempts = 3;
        const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${stxAddress}&limit=${maxAttempts}`;

        try {
            // Step 1: Fetch user's NFT holdings
            const holdingsResponse = await fetch(nftHoldingsUrl);
            const holdingsData = await holdingsResponse.json();

            console.log('Holdings data Mainnet:', holdingsData);

            if (holdingsData.results && holdingsData.results.length > 0) {
                for (let i = 0; i < Math.min(maxAttempts, holdingsData.results.length); i++) {
                    const nft = holdingsData.results[i];
                    console.log(`Checking NFT For Profile Background ${i + 1}:`, nft);

                    const [contractAddress, assetName] = nft.asset_identifier.split('::');
                    const tokenId = nft.value && nft.value.repr ? parseInt(nft.value.repr.replace(/^u/, '')) : null;

                    if (!tokenId) {
                        console.error('Failed to parse token ID:', nft.value);
                        continue; // Skip to next NFT
                    }

                    // Step 2: Fetch NFT metadata using Hiro API
                    const metadataUrl = `https://api.hiro.so/metadata/v1/nft/${contractAddress}/${tokenId}`;
                    console.log('Fetching metadata from:', metadataUrl);

                    try {
                        const metadataResponse = await fetch(metadataUrl);
                        const metadata = await metadataResponse.json();

                        console.log('Metadata:', metadata);

                        // Step 3: Extract and validate the cached image URL
                        const cachedImageUrl = metadata?.metadata?.cached_image;
                        if (cachedImageUrl && await this.isValidImageUrl(cachedImageUrl)) {
                            // Set the background image
                            this.photo.style.backgroundImage = `url('${cachedImageUrl}')`;
                            console.log('NFT image set as background:', cachedImageUrl);
                            return; // Exit the function as we've found a valid image
                        } else {
                            console.log('No valid cached image URL found for this NFT, trying next...');
                        }
                    } catch (metadataError) {
                        console.error('Error fetching metadata for NFT:', metadataError);
                        // Continue to next NFT
                    }
                }
                console.log('No valid NFT images found after checking up to 3 NFTs');
            } else {
                console.log('No NFTs found for the address');
            }
        } catch (error) {
            console.error('Error in checkForNFTBackground:', error);
            console.error('Error stack:', error.stack);
        }
    }

    async checkForNFTBackgroundTestnet() {
        const userData = userSession.loadUserData();
        const userAddress = userData.profile.stxAddress.testnet;
        const stxAddress = userAddress;
        const maxAttempts = 3;
        const nftHoldingsUrl = `https://stacks-node-api.testnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${stxAddress}&limit=${maxAttempts}`;

        try {
            // Step 1: Fetch user's NFT holdings
            const holdingsResponse = await fetch(nftHoldingsUrl);
            const holdingsData = await holdingsResponse.json();

            console.log('Holdings data TESTNET:', holdingsData);

            if (holdingsData.results && holdingsData.results.length > 0) {
                for (let i = 0; i < Math.min(maxAttempts, holdingsData.results.length); i++) {
                    const nft = holdingsData.results[i];
                    console.log(`Checking NFT For Profile Background TESTNET ${i + 1}:`, nft);

                    const [contractAddress, assetName] = nft.asset_identifier.split('::');
                    const tokenId = nft.value && nft.value.repr ? parseInt(nft.value.repr.replace(/^u/, '')) : null;

                    if (!tokenId) {
                        console.error('Failed to parse token ID TESTNET:', nft.value);
                        continue; // Skip to next NFT
                    }

                    // Step 2: Fetch NFT metadata using Hiro API
                    const metadataUrl = `https://api.hiro.so/metadata/v1/nft/${contractAddress}/${tokenId}`;
                    console.log('Fetching metadata from TESTNET:', metadataUrl);

                    try {
                        const metadataResponse = await fetch(metadataUrl);
                        const metadata = await metadataResponse.json();

                        console.log('Metadata TESTNET:', metadata);

                        // Step 3: Extract and validate the cached image URL
                        const cachedImageUrl = metadata?.metadata?.cached_image;
                        if (cachedImageUrl && await this.isValidImageUrl(cachedImageUrl)) {
                            // Set the background image
                            this.photo.style.backgroundImage = `url('${cachedImageUrl}')`;
                            console.log('NFT image set as background TESTNET:', cachedImageUrl);
                            return; // Exit the function as we've found a valid image
                        } else {
                            console.log('TESTNET No valid cached image URL found for this NFT, trying next...');
                        }
                    } catch (metadataError) {
                        console.error('Error fetching metadata for NFT TESTNET:', metadataError);
                        // Continue to next NFT
                    }
                }
                console.log('No valid NFT images found after checking up to 3 NFTs TESTNET');
            } else {
                console.log('No NFTs found for the address TESTNET');
            }
        } catch (error) {
            console.error('Error in checkForNFTBackground TESTNET:', error);
            console.error('Error stack TESTNET:', error.stack);
        }
    }

    async onLongPress(e) {
        e.preventDefault();
        await this.fetchUserNFTs();
        this.showNFTModal();
    }
    
    async fetchUserNFTs() {
        const userData = userSession.loadUserData();
        const userAddress = userData.profile.stxAddress.testnet;
        const nftHoldingsUrl = `https://stacks-node-api.testnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddress}&limit=50`;

        try {
            // Step 1: Fetch user's NFT holdings
            const holdingsResponse = await fetch(nftHoldingsUrl);
            const holdingsData = await holdingsResponse.json();

            console.log('Holdings data:', holdingsData);

            if (holdingsData.results && holdingsData.results.length > 0) {
                // Sort NFTs by block height in descending order (most recent first)
                const sortedNFTs = holdingsData.results.sort((a, b) => b.block_height - a.block_height);

                this.nfts = await Promise.all(sortedNFTs.map(async (nft) => {
                    console.log(`Checking NFT MAINNET:`, nft);

                    const [contractAddress, assetName] = nft.asset_identifier.split('::');
                    const tokenId = nft.value && nft.value.repr ? parseInt(nft.value.repr.replace(/^u/, '')) : null;

                    if (!tokenId) {
                        console.log('Skipping NFT (likely BNS name):', nft.value);
                        return null; // Skip this NFT (likely a BNS name)
                    }

                    // Step 2: Fetch NFT metadata using Hiro API
                    const metadataUrl = `https://api.hiro.so/metadata/v1/nft/${contractAddress}/${tokenId}`;
                    console.log('Fetching metadata from:', metadataUrl);

                    try {
                        const metadataResponse = await fetch(metadataUrl);
                        const metadata = await metadataResponse.json();

                        console.log('Metadata:', metadata);

                        // Step 3: Extract and validate the cached image URL
                        const cachedImageUrl = metadata?.metadata?.cached_image;
                        if (cachedImageUrl && await this.isValidImageUrl(cachedImageUrl)) {
                            return {
                                id: tokenId,
                                imageUrl: cachedImageUrl,
                                name: metadata?.metadata?.name || `NFT #${tokenId}`,
                                blockHeight: nft.block_height
                            };
                        } else {
                            console.log('No valid cached image URL found for this NFT, skipping...');
                            return null;
                        }
                    } catch (metadataError) {
                        console.error('Error fetching metadata for NFT:', metadataError);
                        return null;
                    }
                }));

                // Filter out null entries (skipped NFTs and errors)
                this.nfts = this.nfts.filter(nft => nft !== null);

                console.log('Fetched and processed NFTs:', this.nfts);
            } else {
                console.log('No NFTs found for the address');
                this.nfts = [];
            }
        } catch (error) {
            console.error('Error in fetchUserNFTs:', error);
            console.error('Error stack:', error.stack);
            this.nfts = [];
        }
    }

    async isValidImageUrl(url) {
        try {
            const response = await fetch(url, { method: 'HEAD' });
            return response.ok && response.headers.get('Content-Type').startsWith('image/');
        } catch (error) {
            console.error('Error validating image URL:', error);
            return false;
        }
    }

    async showNFTModal() {
        await this.fetchUserNFTs();

        if (this.nfts.length === 0) {
            alert("No suitable NFTs found for profile picture.");
            return;
        }

        const modal = document.createElement('div');
        modal.classList.add('nft-modal');
        modal.innerHTML = `
        <div class="nft-modal-content">
            <span class="close-modal">&times;</span>
            <h2>Choose NFT for Profile</h2>
            <div class="nft-display">
                <button class="nav-button prev">&lt;</button>
                <div class="nft-image-container">
                    <img src="${this.nfts[0].imageUrl}" alt="${this.nfts[0].name}" class="nft-image">
                </div>
                <button class="nav-button next">&gt;</button>
            </div>
            <p class="nft-name">${this.nfts[0].name}</p>
            <button class="select-nft">Select as Profile Photo</button>
        </div>
    `;

        document.body.appendChild(modal);

        const closeBtn = modal.querySelector('.close-modal');
        const prevBtn = modal.querySelector('.nav-button.prev');
        const nextBtn = modal.querySelector('.nav-button.next');
        const selectBtn = modal.querySelector('.select-nft');

        closeBtn.onclick = () => modal.remove();
        prevBtn.onclick = () => this.navigateNFT('prev');
        nextBtn.onclick = () => this.navigateNFT('next');
        selectBtn.onclick = () => this.selectNFT();

        this.currentNftIndex = 0;
        this.updateNFTDisplay();
    }

    navigateNFT(direction) {
        if (direction === 'next') {
            this.currentNftIndex = (this.currentNftIndex + 1) % this.nfts.length;
        } else {
            this.currentNftIndex = (this.currentNftIndex - 1 + this.nfts.length) % this.nfts.length;
        }
        this.updateNFTDisplay();
    }


    getNFTContent(nft) {
        if (nft.imageUrl) {
            return `<img src="${nft.imageUrl}" alt="${nft.name}" class="nft-image">`;
        } else if (typeof nft.id === 'string') {
            // This is likely a BNS name
            return `<div class="bns-name">${nft.id}</div>`;
        } else {
            return `<div class="nft-placeholder">No image available</div>`;
        }
    }

    updateNFTDisplay() {
        const nftImage = document.querySelector('.nft-image');
        const nftName = document.querySelector('.nft-name');
        const currentNFT = this.nfts[this.currentNftIndex];
        nftImage.src = currentNFT.imageUrl;
        nftImage.alt = currentNFT.name;
        nftName.textContent = currentNFT.name;
    }

    navigateNFT(direction) {
        if (direction === 'next') {
            this.currentNftIndex = (this.currentNftIndex + 1) % this.nfts.length;
        } else {
            this.currentNftIndex = (this.currentNftIndex - 1 + this.nfts.length) % this.nfts.length;
        }
        this.updateNFTDisplay();
    }


    selectNFT() {
        const selectedNFT = this.nfts[this.currentNftIndex];
        this.photo.style.backgroundImage = `url('${selectedNFT.imageUrl}')`;
        document.querySelector('.nft-modal').remove();
    }


    onClick(e) {
        const currentTime = new Date().getTime();
        const timeSinceLastClick = currentTime - this.lastClickTime;

        if (timeSinceLastClick < 500) {
            this.consecutiveClicks++;
            if (this.consecutiveClicks >= 1) {
                this.showTip();
            }
        } else {
            this.consecutiveClicks = 1;
        }

        this.lastClickTime = currentTime;

        this.createWave(e);
        this.createFloatingEmoji(e);

        // Add safe sound handling
        if (this.clickSound) {
            try {
                this.clickSound.currentTime = 0;
                this.clickSound.play().catch(err => {
                    console.log('Could not play click sound:', err);
                });
            } catch (err) {
                console.log('Error with click sound:', err);
            }
        }
    }

    // Helper function to validate image URL
    async isValidImageUrl(url) {
        try {
            const response = await fetch(url, { method: 'HEAD' });
            return response.ok && response.headers.get('Content-Type').startsWith('image/');
        } catch (error) {
            console.error('Error validating image URL:', error);
            return false;
        }
    }

    createWave(e) {
        const wave = document.createElement('div');
        wave.classList.add('wave');
        this.innerContainer.appendChild(wave);

        const rect = this.innerContainer.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        wave.style.width = wave.style.height = `${size}px`;
        wave.style.left = `${x}px`;
        wave.style.top = `${y}px`;

        wave.style.animation = 'ripple 0.1s linear';
        wave.addEventListener('animationend', () => {
            wave.remove();
        });
    }

    showTip() {
        this.photo.classList.add('tipped');
        this.tipText.classList.add('show');
        setTimeout(() => {
            this.photo.classList.remove('tipped');
            this.tipText.classList.remove('show');
        }, 5000); // Show the tip text for 5 seconds
    }

    async onTipClick(e, recipientAddress) {
        e.stopPropagation();
        console.log('Tip clicked! Ready to interact with smart contract.');

    

        // Check if userSession is defined
        if (typeof userSession === 'undefined') {
            console.error('userSession is undefined');
            return;
        }

        // Check if the user is signed in
        if (!userSession.isUserSignedIn()) {
            console.log('User is not signed in');
            // Handle case where user is not signed in 
            return;
        }
       
        try {
            const userData = userSession.loadUserData();
            console.log('User data:', userData);

            if (!userData || !userData.profile || !userData.profile.stxAddress) {
                console.error('User data is incomplete');
                return;
            }
            const senderAddress = userData.profile.stxAddress.mainnet;

            function getAddressFromSubdomain() {
                try {
                    // Get the hostname (e.g., "SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5.gated.so")
                    const hostname = window.location.hostname;
                    console.log('Current hostname:', hostname);

                    // Split by dots and get the first part (the subdomain/address)
                    const subdomain = hostname.split('.')[0].toUpperCase();
                    console.log('Extracted subdomain:', subdomain);

                    // Validate that it's a Stacks address
                    if (subdomain.startsWith('SP') || subdomain.startsWith('ST')) {
                        console.log('Valid Stacks address found:', subdomain);
                        return subdomain;
                    }

                    console.error('Invalid Stacks address format:', subdomain);
                    throw new Error(`Invalid Stacks address in subdomain: ${subdomain}`);
                } catch (error) {
                    console.error('Error getting address from subdomain:', error);
                    throw error;
                }
            }

            // Usage with error handling
            let recipientAddress;
            try {
                recipientAddress = getAddressFromSubdomain();
                console.log('Recipient address set to:', recipientAddress);
            } catch (error) {
                console.error('Failed to get recipient address:', error);
                // Handle the error appropriately
                // Maybe set a default address or show an error message
            }

            console.log('Setting up 1 STX tip. Getting Sender and Recipient information...');
            console.log('Sender address:', senderAddress);
            console.log('Recipient address:', recipientAddress);

            // Define the tip amount (should match the amount in your contract)
            const tipAmount = 1000000; // 1 STX in microSTX

            // Prepare the contract call
            const functionArgs = [
                principalCV(recipientAddress)
            ];
        
        
            const postConditions =
                [Pc.principal(senderAddress).willSendEq(tipAmount).ustx()]
            
            const options = {
                contractAddress: 'SP1ZCYG0D3HCK2F7SY8VH9ZREB0JWCBSAPFNS8V5Z',
                contractName: 'squealing-white-toucan', 
                functionName: 'tip',
                functionArgs,
                network: STACKS_MAINNET,
                postConditions,
                onFinish: data => {
                    console.log('Transaction finished:', data);
                },
                onCancel: () => {
                    console.log('Transaction was canceled');
                }
            };

            console.log('Contract call options:', options);

            await openContractCall(options);
        } catch (error) {
            console.error('Error in onTipClick:', error);
        }
    }

    // Placeholder for future smart contract interaction
    sendTip(amount) {
        // This method would contain the logic to interact with your smart contract
        console.log(`Sending tip of ${amount} STX`);
        // Implement your smart contract interaction here
    }

    createFloatingEmoji(e) {
        const emoji = document.createElement('div');
        emoji.classList.add('floating-emoji');
        emoji.textContent = '💐';

        const rect = this.innerContainer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        emoji.style.left = `${x}px`;
        emoji.style.top = `${y}px`;

        this.outerContainer.appendChild(emoji);

        emoji.addEventListener('animationend', () => {
            emoji.remove();
        });
    }

    onMouseMove(e) {
        const distanceX = e.clientX - this.centerX;
        const distanceY = e.clientY - this.centerY;
        const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
        const maxDistance = Math.min(window.innerWidth, window.innerHeight) / 2;
        const moveX = (distanceX / maxDistance) * this.maxDistance;
        const moveY = (distanceY / maxDistance) * this.maxDistance;

        requestAnimationFrame(() => {
            this.outerContainer.style.transform = `translate(${moveX}px, ${moveY}px)`;
        });
    }

    onResize() {
        this.centerX = window.innerWidth / 2;
        this.centerY = window.innerHeight / 2;
    }
}

async function checkTokenBalance(userAddress, tokenContractAddress) {
    const apiUrl = 'https://stacks-node-api.mainnet.stacks.co';
    const endpoint = `${apiUrl}/extended/v1/address/${userAddress}/balances`;

    try {
        const response = await fetch(endpoint);
        const data = await response.json();

        // Check if the token exists in the user's balance
        const tokenBalance = data.fungible_tokens[tokenContractAddress];
        console.log(data.fungible_tokens)
        console.log("You have more than the required amount of tokens")

        if (tokenBalance && parseInt(tokenBalance.balance) > 0) {
            return true; // User has tokens
        } else {
            return false; // User doesn't have tokens
        }
    } catch (error) {
        console.error('Error checking token balance:', error);
        return false;
    }
}

async function getBitcoinAddressInfo(address) {
    const HIRO_API_BASE = 'https://api.testnet.hiro.so/v1';

    try {
        const response = await fetch(
            `${HIRO_API_BASE}/addresses/stacks/${address}`
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching Bitcoin address info:', error);
        return null;
    }
}

// async function getBNSv2() {
//     const userData = userSession.loadUserData();
//     const userAddressMainnet = userData.profile.stxAddress.mainnet;
//     const userAddressTestnet = userData.profile.stxAddress.testnet;

//     // Initialize variables outside if blocks
//     let addressInfoMainnet = null;
//     let addressInfoTestnet = null;
//     let bnsv2NameMainnet = null;
//     let bnsv2NameTestnet = null;

//     try {
//         // Fetch mainnet data if available
//         if (userData.profile.stxAddress.mainnet) {
//             addressInfoMainnet = await getBitcoinAddressInfo(userAddressMainnet);
//             bnsv2NameMainnet = addressInfoMainnet?.names?.[0];
//             console.log('Bitcoin address info MAINNET:', bnsv2NameMainnet);
//         }

//         // Fetch testnet data if available
//         if (userData.profile.stxAddress.testnet) {
//             addressInfoTestnet = await getBitcoinAddressInfo(userAddressTestnet);
//             bnsv2NameTestnet = addressInfoTestnet?.names?.[0];
//             console.log('Bitcoin address info TESTNET:', bnsv2NameTestnet);
//         }

//         const displayElement = document.getElementById('bnsv2NameDisplay');
//         if (!displayElement) {
//             console.error('Display element not found');
//             return;
//         }

//         // Handle mainnet display
//         if (bnsv2NameMainnet) {
//             displayElement.textContent = "@" + bnsv2NameMainnet;
//             displayElement.style.fontSize = '24px';
//             displayElement.className = 'bns-name';
//         } else if (addressInfoMainnet) {
//             displayElement.textContent = userAddressMainnet;
//             displayElement.style.fontSize = '18px';
//             displayElement.className = 'bns-name-address';
//         }

//         // Handle testnet display if no mainnet name was found
//         if (!bnsv2NameMainnet && bnsv2NameTestnet) {
//             displayElement.textContent = "@" + bnsv2NameTestnet;
//             displayElement.style.fontSize = '24px';
//             displayElement.className = 'bns-name';
//         } else if (!bnsv2NameMainnet && addressInfoTestnet) {
//             displayElement.textContent = userAddressTestnet;
//             displayElement.style.fontSize = '18px';
//             displayElement.className = 'bns-name-address';
//         }

//     } catch (error) {
//         console.error('Error in getBNSv2:', error);
//         const displayElement = document.getElementById('bnsv2NameDisplay');
//         if (displayElement) {
//             displayElement.textContent = userAddressMainnet || userAddressTestnet || 'Error loading address';
//             displayElement.style.fontSize = '18px';
//             displayElement.className = 'bns-name-address';
//         }
//     }
// }
  // Use `new StacksMainnet()` for main network

// GET NFTS FROM WALLET

async function fetchNFTs(walletAddress) {
    const url = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${walletAddress}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();

        console.log('NFT Data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching NFT data:', error);
    }
}

// GET METADATA FOR NFT VIA HIRO API
async function fetchNFTMetadata(principal, tokenId) {
    const url = `https://stacks-node-api.mainnet.stacks.co/metadata/v1/nft/${principal}/${tokenId}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const metadata = await response.json();
        console.log('NFT Metadata:', metadata);
        return metadata;
    } catch (error) {
        console.error('Error fetching NFT metadata:', error);
    }
}

// Define the function as async
async function stakeTokens() {

    try {

        if (userSession.isUserSignedIn()) {
            const userData = userSession.loadUserData();
            const contractAddress = 'SP2ZNGJ85ENDY6QRHQ5P2D4FXKGZWCKTB2T0Z55KS';
            const contractName = 'liquid-staked-welsh-v2';
            const functionName = 'stake';
            const functionArgs = [uintCV(1)]; // Replace 100000 with the actual amount to stake

            const txOptions = {
                contractAddress,
                contractName,
                functionName,
                functionArgs,
                network,
                senderKey: userData.appPrivateKey
            };

            const transaction = await openContractCall(txOptions);
            const txResult = await broadcastTransaction(transaction, network);
            console.log('Transaction ID:', txResult.txid);
            console.log('Transaction Result:', txResult);
        }
    } catch (error) {
        console.error('Failed to stake tokens:', error);
    }
}

// BUY WELSH BUTTON
// const verifyButton = document.getElementById('verify-token-button');
// if (verifyButton && userSession.isUserSignedIn()) {
//     verifyButton.addEventListener('click', function () {
//         const userData = userSession.loadUserData();
//         const userAddress = userData.profile.stxAddress.mainnet;
//         //checkAccess(userAddress, 'SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6.leo-token::leo');
//         if (checkAccess) {
//             console.log("User is Authenticated & Access has been granted")
//             document.getElementById('popupContainer2').style.display = 'none';
//             document.getElementById('overlay').style.display = 'none';
//         }
//     }
//     )
// } else {
//     triggerBlinkRed();
//     console.log("No Wallet Connected");
// };

function triggerBlinkRed() {
    const blinkOverlay = document.getElementById('blinkOverlay');
    blinkOverlay.style.opacity = '0.5';
    setTimeout(() => {
        blinkOverlay.style.opacity = '0';
    }, 1000);
}

// DOES USER HAVE TOKEN TO UNLOCK
// async function checkAccess(userAddress, tokenContractAddress) {
//     const hasTokens = await checkTokenBalance(userAddress, tokenContractAddress);

//     if (hasTokens) {
//         console.log('Access granted');
//         const unlocked = document.getElementById('textUnlock');
//         const lockableSection1 = document.getElementById('lockable-section-1');
//         const lockableSection2 = document.getElementById('lockable-section-2');
//         unlocked.style.display = 'block';
//         lockableSection1.style.display = 'block';
//         lockableSection2.style.disabled = 'none';
//     } else {
//         console.log('Access denied');
//         const unlocked = document.getElementById('textUnlock');
//         unlocked.style.display = 'none';
//         triggerBlinkRed();
//     }
// };

function formatAccountNumber(accountNumber) {
    // Check if the account number is long enough
    if (accountNumber.length < 8) {
        console.error("Account number too short!");
        return accountNumber;  // Optionally handle this scenario differently
    }

    // Get the first 4 and last 4 characters
    const firstFour = accountNumber.slice(0, 4);
    const lastFour = accountNumber.slice(-4);

    // Combine them with a mask in between
    return `${firstFour}****${lastFour}`;
}

// Show overlay on button 
document.getElementById('showPopupButton1b').addEventListener('click', function () {
    var gateIcon = document.getElementById('showPopupButton1b');
    var claimButton = document.getElementById('claimButton');
    var musicPlayer = document.getElementById('music-player-sticky');

    triggerBlinkRed();
    claimButton.style.display = 'block';
    claimButton.style.zIndex = '1000'; // Ensure it's above sticky player

    // If music player exists and is initialized, update position through it
    if (window.musicPlayer && window.musicPlayer.updateClaimButtonPosition) {
        window.musicPlayer.updateClaimButtonPosition();
    } else {
        // Fallback if music player isn't initialized
        if (musicPlayer && musicPlayer.style.display === 'flex') {
            const playerHeight = musicPlayer.offsetHeight;
            claimButton.style.bottom = `${playerHeight + 20}px`;
        } else {
            claimButton.style.bottom = '20px';
        }
    }

    const userData = window.router.auth.userSession.loadUserData();
    const userAddress = userData.profile.stxAddress.mainnet;

    claimButton.addEventListener('click', function () {
        window.router.navigateToAdmin(userAddress);
    });
});

// Trigger green blink effect
async function triggerBlinkGreen() {
    var blinkOverlay = document.getElementById('blinkOverlay2');
    blinkOverlay.style.display = 'block';
    blinkOverlay.addEventListener('animationend', function () {
        blinkOverlay.style.display = 'none'; // Hide after animation
    });
}
let isHovering = false;
let rafId = null;
let lastX = 0;
let lastY = 0;
let currentX = 0;
let currentY = 0;

// Function to smoothly update clip path
function smoothUpdateClipPath() {
    if (isHovering) {
        const dx = (lastX - currentX) * 0.2;
        const dy = (lastY - currentY) * 0.2;

        currentX += dx;
        currentY += dy;

        clearLayer.style.clipPath = `circle(5px at ${currentX}px ${currentY}px)`;

        rafId = requestAnimationFrame(smoothUpdateClipPath);
    }
}

// Throttle function to limit the frequency of function calls
function throttle(func, limit) {
    let inThrottle;
    return function (...args) {
        if (!inThrottle) {
            func.apply(this, args);
            inThrottle = true;
            setTimeout(() => inThrottle = false, limit);
        }
    }
}

// Throttled function to update mouse/touch position
const updatePosition = throttle((x, y) => {
    lastX = x;
    lastY = y;

    if (!isHovering) {
        isHovering = true;
        clearLayer.style.transition = 'none';
        rafId = requestAnimationFrame(smoothUpdateClipPath);
    }
}, 16); // ~60fps

// // Handle mouse movement
// document.addEventListener('mousemove', (e) => {
//     const rect = container.getBoundingClientRect();
//     const x = e.clientX - rect.left;
//     const y = e.clientY - rect.top;
//     updatePosition(x, y);
// });

// // Handle mouse or touch end
// function resetHoverState() {
//     isHovering = false;
//     clearLayer.style.transition = 'clip-path 0.5s ease';
//     clearLayer.style.clipPath = 'circle(0px at 50% 50%)';
//     if (rafId) {
//         cancelAnimationFrame(rafId);
//         rafId = null;
//     }
// }

// Add a specific key press to reset the hover state
document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
        resetHoverState();
    }
});

// Initialize
//clearLayer.style.clipPath = 'circle(0px at 0px 0px)';


// {
//     title: "Exclusive Music Release",
//     description: "Be the first to hear our latest track and get behind-the-scenes content.",
//     isTokenGated: true,
//     unlockConditions: [
//         { type: 'tokenHolding', tokens: ['WELSH'] },
//         { type: 'minimumBalance', amount: 10, currency: 'STX' }
//     ],
//     gatedContent: {
//         title: "New Track: 'Blockchain Beats'",
//         description: "Listen to our latest single and view exclusive behind-the-scenes footage."
//     }
// },
//     {
//         title: "Token Access",
//         description: "This content is exclusively available to holders of the following tokens. Verify your wallet to unlock:",
//         isSellable: true,
//         isTokenGated: true,
//         requiredTokens: ["WELSH"],
//         unlockConditions: [
//             { type: 'tokenHolding', tokens: ['WELSH'] },
//             { type: 'minimumBalance', amount: 1, currency: 'STX' }
//         ],
//         gatedContent: {
//             title: "Exclusive Music Release",
//             description: "Be the first to hear our latest track and get behind-the-scenes content."
//         }
//     },
//     {
//         title: "STX London Club",
//         description: "NFTs to unlock events with Stacks London",
//         isMultiNFT: true, // New property to identify multi-NFT items
//         nfts: [
//             {
//                 name: "BRONZE",
//                 icon: "🥉",
//                 minted: 76,
//                 total: 103,
//                 description: "STX London Community Token",
//                 price: 0,
//                 benefits: [
//                     "Exclusive access to Corgi-themed events",
//                     "Voting rights in Corgi DAO",
//                     "Priority minting for future Corgi collections"
//                 ],
//                 imageUrl: "/roo.png"
//             },
//             {
//                 name: "SILVER",
//                 icon: "🥈",
//                 minted: 0,
//                 total: 103,
//                 description: "STX London Community Token",
//                 price: 20,
//                 benefits: [
//                     "VIP access to virtual cat shows",
//                     "Customizable Siamese cat avatars",
//                     "Discounts on real-world cat products"
//                 ],
//                 imageUrl: "/roo.png"
//             },
//             {
//                 name: "PLATINUM",
//                 icon: "🥇",
//                 minted: 4,
//                 total: 103,
//                 description: "STX London Community Token",
//                 price: 100,
//                 benefits: [
//                     "Entry to exclusive rabbit-themed metaverse",
//                     "Yearly airdrop of carrot tokens",
//                     "Collaboration opportunities with rabbit artists"
//                 ],
//                 imageUrl: "/roo.png"
//             }
//         ]
//     }
// ];

// const unlockingMethods = {
//     tokenHolding: {
//         name: 'Token Holding',
//         check: (userTokens, requiredTokens) => {
//             return requiredTokens.every(token => userTokens.includes(token));
//         }
//     },
//     minimumBalance: {
//         name: 'Minimum Balance',
//         check: (userBalance, requiredBalance) => userBalance >= requiredBalance
//     },
//     nftOwnership: {
//         name: 'NFT Ownership',
//         check: (userNFTs, requiredNFT) => userNFTs.includes(requiredNFT)
//     }
// // };



// Add a new item to your storeItems array
// const storeItems = [
//     {
//         title: "When Walls Break",
//         subtitle: "Various Artists",
//         albumImage: albumImage,
//         description: "The Stacks Music Project supports collaboration between musicians and visual artists with an immersive audio-visual experience.  A decentralized NFT album on the blockchain.",
//         isMusicPlayer: true, // New property to identify music player items
//         monthlyListeners: 617,
//         albumPrice: 80,
//         albumSupply: 100,
//         albumCoverArtist: "Reese",
//         songs: [
//             { songArtist: "Josie Field", songName: "Radio Silence", duration: "3:29", audioUrl: track1Audio, imgUrl: track1Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
//             { songArtist: "Ard Matthews", songName: "You Make it Alright", duration: "4:10", audioUrl: track2Audio, imgUrl: track2Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
//             { songArtist: "Arno Carstens", songName: "Mortals", duration: "4:43", audioUrl: track3Audio, imgUrl: track3Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
//             { songArtist: "Kahn Morbee", songName: "Buzz Head", duration: "2:35", audioUrl: track4Audio, imgUrl: track4Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
//             { songArtist: "Laurie Levine", songName: "Grey", duration: "4:54", audioUrl: track5Audio, imgUrl: track5Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
//             { songArtist: "Jacques Moolman", songName: "Exile", duration: "4:23", audioUrl: track6Audio, imgUrl: track6Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
//             { songArtist: "Evert Synman", songName: "I Shouldn't Break", duration: "3:08", audioUrl: track7Audio, imgUrl: track7Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
//             { songArtist: "Xander", songName: "BBQ to Braai", duration: "1:48", audioUrl: track8Audio, imgUrl: track8Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 }
//         ]
//     },
// ]

// Utility function to safely select DOM elements
function safeSelect(selector) {
    const element = document.querySelector(selector);
    if (!element) {
        console.warn(`Element not found: ${selector}`);
    }
    return element;
}

// Utility function to safely select DOM elements
const $ = (selector) => document.querySelector(selector);


let audioTimer;
let countdownInterval;
const MAX_PLAY_TIME = 30; // 30 seconds limit

// const playlist = storeItems[0].songs; // Use the songs from your storeItems
let currentTrackIndex = 0;

function showStickyPlayer() {
    const stickyPlayer = document.getElementById('music-player-sticky');
    if (stickyPlayer) {
        stickyPlayer.style.display = 'flex'; // or 'block', depending on your layout
    }
}

function hideStickyPlayer() {
    const stickyPlayer = document.getElementById('music-player-sticky');
    if (stickyPlayer) {
        stickyPlayer.style.display = 'none';
    }
}

function showErrorToUser(message) {
    console.error(message);
    // Implement user-facing error display here
}

function updatePlayPauseButton(isPlaying) {
    if (playPauseBtn) {
        playPauseBtn.innerHTML = isPlaying ? '<i class="fa-solid fa-pause"></i>' : '<i class="fa-solid fa-play"></i>';
    }
    if (controlButtons.play) {
        controlButtons.play.innerHTML = isPlaying ? '<i class="fa-solid fa-pause"></i>' : '<i class="fa-solid fa-play"></i>';
    }
}

function updateActiveTrack() {
    if (!songList) return;
    songList.querySelectorAll('li').forEach((li, index) => {
        li.classList.toggle('active-track', index === currentTrackIndex && !audioPlayer.paused);
    });
}

function loadTrack(index) {
    if (!audioPlayer || index < 0 || index >= playlist.length) {
        showErrorToUser("Failed to load track. Please try another.");
        return;
    }

    const track = playlist[index];
    audioPlayer.src = track.audioUrl;
    if (trackInfo) trackInfo.textContent = `${track.songArtist} - ${track.songName}`;
    if (albumArt) albumArt.src = track.imgUrl;

}

function togglePlayPause() {
    if (!audioPlayer) return;
    if (audioPlayer.paused) {
        if (audioPlayer.currentTime >= MAX_PLAY_TIME) {
            audioPlayer.currentTime = 0;
        }
        audioPlayer.play().then(() => {
            updatePlayPauseButton(true);
            startAudioTimer();
            startCountdown();
            showStickyPlayer(); // Show the sticky player when music starts
        }).catch(e => {
            showErrorToUser("Failed to play audio. Please try again.");
        });
    } else {
        audioPlayer.pause();
        updatePlayPauseButton(false);
        resetAudioTimer();
        clearInterval(countdownInterval);
        // Optionally, you can hide the sticky player when paused
    }
}

function startAudioTimer() {
    resetAudioTimer();
    audioTimer = setTimeout(() => {
        if (audioPlayer) {
            audioPlayer.pause();
            updatePlayPauseButton(false);
        }
    }, MAX_PLAY_TIME * 1000);
}

function resetAudioTimer() {
    clearTimeout(audioTimer);
}

function startCountdown() {
    clearInterval(countdownInterval);
    countdownInterval = setInterval(() => {
        if (!audioPlayer) return;
        const timeLeft = Math.ceil(MAX_PLAY_TIME - audioPlayer.currentTime);
        updateCountdown(timeLeft);
        if (timeLeft <= 0) {
            clearInterval(countdownInterval);
        }
    }, 1000);
}

function updateCountdown(timeLeft) {
    if (!songDuration) return;
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    songDuration.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
}

// Event Listeners
// [playPauseBtn, controlButtons.play].filter(Boolean).forEach(btn => {
//     btn.addEventListener('click', togglePlayPause);
// });

// if (volumeControl && audioPlayer) {
//     volumeControl.addEventListener('input', () => {
//         audioPlayer.volume = volumeControl.value;
//     });
// }

// if (progressBar && audioPlayer) {
//     progressBar.addEventListener('input', () => {
//         const maxDuration = Math.min(audioPlayer.duration, MAX_PLAY_TIME);
//         const seekTime = (progressBar.value / 100) * maxDuration;
//         audioPlayer.currentTime = seekTime;
//     });
// }


function playPreviousTrack() {
    currentTrackIndex = (currentTrackIndex - 1 + playlist.length) % playlist.length;
    loadTrack(currentTrackIndex);
    resetAndRestartPlayback();

}

function playNextTrack() {
    currentTrackIndex = (currentTrackIndex + 1) % playlist.length;
    loadTrack(currentTrackIndex);
    resetAndRestartPlayback();
}

function resetAndRestartPlayback() {
    if (audioPlayer) {
        audioPlayer.currentTime = 0;
        resetAudioTimer();
        clearInterval(countdownInterval);
        audioPlayer.play().then(() => {
            updatePlayPauseButton(true);
            startAudioTimer();
            startCountdown();
            showStickyPlayer();
        }).catch(e => {
            showErrorToUser("Failed to play audio. Please try again.");
        });
    }
}

// if (prevBtn) prevBtn.addEventListener('click', playPreviousTrack);
// if (controlButtons.previous) controlButtons.previous.addEventListener('click', playPreviousTrack);
// if (nextBtn) nextBtn.addEventListener('click', playNextTrack);
// if (controlButtons.next) controlButtons.next.addEventListener('click', playNextTrack);

// if (audioPlayer) {
//     audioPlayer.addEventListener('timeupdate', () => {
//         if (progressBar) {
//             const maxDuration = Math.min(audioPlayer.duration, MAX_PLAY_TIME);
//             const progress = (audioPlayer.currentTime / maxDuration) * 100;
//             progressBar.value = progress;
//         }
//     });

//     audioPlayer.addEventListener('ended', () => {
//         updatePlayPauseButton(false);
//         resetAudioTimer();
//         clearInterval(countdownInterval);
//         // Optionally, you can add auto-play next track functionality here
//         loadTrack((currentTrackIndex + 1) % playlist.length);
//     });
// }

function createMusicPlayerCard(item) {

    const card = document.createElement('div');
    card.className = 'music-player-card';

    const cardheaderContainer = document.createElement('div');
    cardheaderContainer.className = 'card-header-container';


    const cardheader = document.createElement('div');
    cardheader.className = 'card-header';

    // Dynamically set the background image using the item.albumImage property
    cardheader.style.backgroundImage = `url(${item.albumImage})`;

    const albumHeaderContainer = document.createElement('div');
    albumHeaderContainer.className = 'two-column-container';

    // Artist info
    const artistInfo = document.createElement('div');
    artistInfo.className = 'artist-info column';
    albumHeaderContainer.appendChild(artistInfo);

    const buyButtonContainer = document.createElement('div');
    buyButtonContainer.className = 'buyButtonContainer';


    const albumName = document.createElement('div');
    albumName.className = 'album-title';
    albumName.textContent = item.title;
    artistInfo.appendChild(albumName);

    const subtitleName = document.createElement('div');
    subtitleName.className = 'music-subtitle';
    subtitleName.textContent = item.subtitle;
    artistInfo.appendChild(subtitleName);

    const buyAlbumButton = document.createElement('button');
    buyAlbumButton.className = 'buy-album-button';
    buyAlbumButton.textContent = 'Buy Album';
    buyButtonContainer.appendChild(buyAlbumButton);
    buyButtonContainer.appendChild(buyAlbumButton);

    // Event listeners
    buyAlbumButton.addEventListener('click', async (e) => {
        e.preventDefault();
        console.log('Minting album');
        await handleMint('album');

    });


    card.appendChild(albumHeaderContainer);

    const musicHeader = document.createElement('div');
    musicHeader.className = 'music-header'

    // Music player
    const player = document.createElement('div');
    player.className = 'music-player';

    cardheaderContainer.appendChild(cardheader);
    player.appendChild(cardheaderContainer);
    // Add album player controls
    const albumControls = document.createElement('div');
    albumControls.className = 'album-controls';

    const albumPlayPauseBtn = document.createElement('button');
    albumPlayPauseBtn.id = 'album-play-pause-btn';
    albumPlayPauseBtn.innerHTML = '<i class="fa-solid fa-play"></i>';

    const albumPrevBtn = document.createElement('button');
    albumPrevBtn.id = 'album-prev-btn';
    albumPrevBtn.innerHTML = '<i class="fa-solid fa-backward"></i>';

    const albumNextBtn = document.createElement('button');
    albumNextBtn.id = 'album-next-btn';
    albumNextBtn.innerHTML = '<i class="fa-solid fa-forward"></i>';

    const currentSong = document.createElement('div');
    currentSong.className = 'current-song';

    const songDetailsContainer = document.createElement('div');
    songDetailsContainer.className = 'song-details-container';

    songDetailsContainer.style.display = 'flex';
    songDetailsContainer.style.flexDirection = 'column';

    const songName = document.createElement('div');
    songName.className = 'song-name';
    songName.textContent = item.songs[0].songName;
    songDetailsContainer.appendChild(songName);

    const songArtist = document.createElement('div');
    songArtist.className = 'song-artist';
    songArtist.textContent = item.songs[0].songArtist;
    songDetailsContainer.appendChild(songArtist);

    // Append the container to the current song element
    currentSong.appendChild(songDetailsContainer);

    const songDetailsContainer2 = document.createElement('div');
    songDetailsContainer2.className = 'song-details-container';
    currentSong.appendChild(songDetailsContainer2);
    currentSong.appendChild(albumControls);

    const albumPrice = document.createElement('div');
    albumPrice.className = 'album-price';
    albumPrice.textContent = item.albumPrice + " STX";
    songDetailsContainer2.appendChild(albumPrice);

    const songSupply = document.createElement('div');
    songSupply.className = 'album-supply';
    songSupply.textContent = "Availability:" + "15" + "/" + item.songs[0].songSupply;
    songDetailsContainer2.appendChild(songSupply);

    const imgArtist = document.createElement('div');
    imgArtist.className = 'image-artist'
    imgArtist.textContent = "Artwork by" + " " + item.songs[0].imgArtist;
    songDetailsContainer2.appendChild(imgArtist);

    player.appendChild(currentSong);
    player.appendChild(buyButtonContainer);

    card.appendChild(player);

    const controls = document.createElement('div');
    controls.className = 'player-controls';

    // Synchronize album and sticky players
    synchronizePlayers(albumPlayPauseBtn, albumPrevBtn, albumNextBtn);

    function synchronizePlayers(albumPlayPauseBtn, albumPrevBtn, albumNextBtn) {
        const stickyPlayPauseBtn = document.getElementById('play-pause-btn');
        const stickyPrevBtn = document.getElementById('prev-btn');
        const stickyNextBtn = document.getElementById('next-btn');
    }

    function updateCardHeaderImage(index) {
        const currentSong = item.songs[index];
        cardheader.style.backgroundImage = `url(${currentSong.imgUrl})`;
    }

    const songInfo = document.createElement('div');
    songInfo.className = 'song-info';

    const songList = document.createElement('ul');
    songList.className = 'song-list';

    card.appendChild(songInfo);

    // Create a modal container
    const modal = document.createElement('div');
    modal.className = 'image-modal';
    modal.style.display = 'none';
    document.body.appendChild(modal);

    // Create a loading indicator
    const loader = document.createElement('div');
    loader.className = 'loader';
    loader.textContent = 'Loading...';
    modal.appendChild(loader);

    // Populate the song list
    item.songs.forEach((song, index) => {
        const li = document.createElement('li');
        li.className = 'container-song';
        li.innerHTML = `
            <span class="song-number">${index + 1}</span>
            <img class="song-image" src="${song.imgUrl}" alt="${song.songName} cover" width="40" height="40">
            <div class="container-song-info">
            <span class="song-title">${song.songName}</span>
            <span class="song-artist">${song.songArtist}</span>
            </div>
            <span class="song-duration">${song.duration}</span>
            <button class="mint-track-btn">Buy Track</button>
        `;

        // Add click event for buying the track
        const buyButtonTrack = li.querySelector('.mint-track-btn');
        buyButtonTrack.addEventListener('click', (e) => {
            e.stopPropagation(); // Prevent triggering the song play event
            handleMint('track', index + 1);
        });

        

        // // Add click event to expand the image
        // const img = li.querySelector('.song-image');
        // img.addEventListener('click', (e) => {
        //     e.stopPropagation(); // Prevent triggering the li click event
        //     modal.style.display = 'flex';
        //     loader.style.display = 'block';

        //     const fullImg = new Image();
        //     fullImg.onload = () => {
        //         loader.style.display = 'none';
        //         modal.innerHTML = '';
        //         fullImg.className = 'modal-image';
        //         modal.appendChild(fullImg);

        //         // Trigger reflow to ensure the animation plays
        //         void fullImg.offsetWidth;
        //         fullImg.classList.add('show');
        //     };
        //     fullImg.src = song.imgUrl;
        //    });




        songList.appendChild(li);
    });

    // Close modal when clicking outside the image or pressing Escape
    modal.addEventListener('click', () => {
        modal.style.display = 'none';
    });

    document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && modal.style.display === 'flex') {
            modal.style.display = 'none';
        }
    });

    // Append the song list to the card
    songInfo.appendChild(songList);

    // Modify the CSS for the active track
    const style = document.createElement('style');
    style.textContent = `
        .music-player-card .song-list .active-track {
            color: #ffffff !important;
            opacity: 1 !important;
        }
    `;
    document.head.appendChild(style);

    return card;
}

async function handleMint(type, trackNumber = null) {
    if (window.router.auth.userSession.isUserSignedIn()) {
        try {
            const userData = window.router.auth.userSession.loadUserData();
            const userAddress = userData.profile.stxAddress.mainnet;
            const shortAddress = formatAccountNumber(userAddress);
            console.log('STX Address:', userData.profile.stxAddress.mainnet);

            const senderAddress = userData.profile.stxAddress.testnet;
            console.log('Sender address:', senderAddress);

            let functionName, functionArgs, postConditionAmount;

            const trackNameMap = {
                1: 'radio-silence',
                2: 'you-make-it-alright',
                3: 'mortals',
                4: 'buzz-head',
                5: 'grey',
                6: 'exile',
                7: 'i-shouldnt-break',
                8: 'bbq-to-braai'
            };

            if (type === 'album') {
                functionName = 'mint-album';
                postConditionAmount = 80000000; // album-price + provider-fee + gated-fee
            } else if (type === 'track') {
                if (!trackNumber || trackNumber < 1 || trackNumber > 8) {
                    throw new Error('Invalid track number');
                }
                functionName = `mint-track-${trackNumber}`;
                postConditionAmount = 10000000; // track-price + provider-fee + gated-fee

                // Get the correct NFT token name based on track number
                const nftTokenName = trackNameMap[trackNumber];
                console.log(nftTokenName)
            } else {
                throw new Error('Invalid minting type');
            }

            functionArgs = [
                principalCV(senderAddress)
            ];
            const nftTokenName = trackNameMap[trackNumber];

            const NFTID = 'ST9Q27FYB7DRFNRW51JY7J7QXCKQQ5VHJBJ8BEDW.azure-thrush-one-2::' + nftTokenName

            let postConditions = [
                // STX post condition always included
                Pc.principal(senderAddress)
                    .willSendEq(postConditionAmount)
                    .ustx(),
            ];

  

            const options = {
                contractAddress: 'ST9Q27FYB7DRFNRW51JY7J7QXCKQQ5VHJBJ8BEDW',
                contractName: 'azure-thrush-one-2',
                functionName: functionName,
                functionArgs: functionArgs,
                network: STACKS_TESTNET,
                postConditions: postConditions,
                onFinish: data => {
                    console.log('Transaction finished:', data);
                    // Add UI update logic here
                },
                onCancel: () => {
                    console.log('Transaction was canceled');
                    // Add UI update logic here
                }
            };

            console.log('Contract call options:', options);
            await openContractCall(options);
        } catch (error) {
            console.error('Error in handleMint:', error);
        }
    } else {
        console.log('User is not signed in. Initiating sign-in process.');
        showConnect({
            appDetails: {
                name: 'GATED',
                icon: gatedLogo,
            },
            redirectTo: '/',
            onFinish: () => {
                // Handle successful authentication
                const userData = userSession.loadUserData();
                const userAddressMainnet = userData.profile.stxAddress.mainnet;
                const userAddressTestnet = userData.profile.stxAddress.testnet;

                const shortAddressMainnet = formatAccountNumber(userAddressMainnet);
                const shortAddressTestnet = formatAccountNumber(userAddressTestnet);

                
                console.log('Authenticated as:', userData);
                console.log('STX Address:', shortAddressMainnet);

                // Update UI after successful connection
                updateUIAfterSignIn(shortAddress);

                // Check access and proceed with minting
                if (checkAccess(userAddressMainnet, 'SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6.leo-token::leo')) {
                    console.log("User is Authenticated & Access has been granted");
                    triggerBlinkGreen();
                    // Re-attempt minting after successful sign-in
                    handleMint(type, trackNumber);
                }
            },
            userSession: window.router.auth.userSession,
        });
    }
}

function createNFTCard(nft) {
    const card = document.createElement('div');
    card.className = 'nft-card';

    // NFT Image
    const imageContainer = document.createElement('div');
    imageContainer.className = 'nft-image-container';
    const image = document.createElement('img');
    image.src = nft.imageUrl;
    image.alt = `${nft.name} preview`;
    image.className = 'nft-image';
    imageContainer.appendChild(image);
    card.appendChild(imageContainer);

    // Icon and Name
    const header = document.createElement('div');
    header.className = 'nft-header';
    header.innerHTML = `
<div class="nft-icon">${nft.icon}</div>
<h3 class="nft-name">${nft.name}</h3>
`;
    card.appendChild(header);

    // Description and Mint Status container
    const infoContainer = document.createElement('div');
    infoContainer.className = 'nft-info-container';

    // Description
    const description = document.createElement('div');
    description.className = 'nft-description';
    description.textContent = nft.description;
    infoContainer.appendChild(description);

    // Amount Minted vs Supply
    const mintStatus = document.createElement('div');
    mintStatus.className = 'nft-mint-status';
    mintStatus.innerHTML = `
<span class="minted">${nft.minted}</span>
<span class="separator">/</span>
<span class="total">${nft.total}</span>
`;
    infoContainer.appendChild(mintStatus);

    card.appendChild(infoContainer);

    // Benefits Toggle Button
    const benefitsButton = document.createElement('button');
    benefitsButton.className = 'nft-benefits-toggle';
    benefitsButton.innerHTML = `
Show Benefits
<span class="chevron-down">▼</span>
`;
    card.appendChild(benefitsButton);

    // Create a container for benefits (initially empty)
    const benefitsContainer = document.createElement('div');
    benefitsContainer.className = 'benefits-container';
    card.appendChild(benefitsContainer);

    benefitsButton.addEventListener('click', () => toggleBenefits(benefitsContainer, nft.benefits, benefitsButton));

    // Mint Button
    const mintButton = document.createElement('button');
    mintButton.className = 'nft-mint-button';
    mintButton.textContent = nft.price === 0 ? "Free Mint" : `Mint for ${nft.price} STX`;
    mintButton.addEventListener('click', () => handleMint(nft));
    card.appendChild(mintButton);

    console.log('Music player card created successfully');


}

function toggleBenefits(benefitsContainer, benefits, toggleButton) {
    if (benefitsContainer.children.length > 0) {
        // If benefits are shown, hide them
        benefitsContainer.innerHTML = '';
        toggleButton.innerHTML = 'Show Benefits <span class="chevron-down">▼</span>';
    } else {
        // If benefits are hidden, show them
        const benefitsList = document.createElement('ul');
        benefitsList.className = 'benefits-list';
        benefits.forEach(benefit => {
            const li = document.createElement('li');
            li.textContent = benefit;
            benefitsList.appendChild(li);
        });
        benefitsContainer.appendChild(benefitsList);
        toggleButton.innerHTML = 'Hide Benefits <span class="chevron-up">▲</span>';
    }
}

function setDropShadow() {
    console.log('setDropShadow function is called');

    const headerElement = document.querySelector('.card-header');
    if (!headerElement) {
        console.error('No .card-header element found');
        return;
    }

    const backgroundImageUrl = extractBackgroundImageUrl(headerElement);

    if (!backgroundImageUrl) {
        console.error('No background image found');
        return;
    }

    console.log('Background Image URL:', backgroundImageUrl);

    const img = new Image();
    img.crossOrigin = "Anonymous"; // Handle cross-origin issues if needed
    img.src = backgroundImageUrl;

    img.onload = function () {
        console.log('Image loaded successfully');
        try {
            const colorThief = new ColorThief();
            const palette = colorThief.getPalette(img, 4);

            const colors = palette.map(color => `rgb(${color[0]}, ${color[1]}, ${color[2]})`);
            console.log('Extracted colors for shadow:', colors);

            // Apply the drop shadow with the extracted colors
            applyDropShadow(colors, headerElement);
        } catch (error) {
            console.error('Error extracting colors with ColorThief:', error);
            const defaultShadowColor = 'rgba(0, 0, 0, 0.5)';
            applyDropShadow([defaultShadowColor], headerElement);
        }
    };

    img.onerror = function () {
        console.error('Error loading image from URL:', backgroundImageUrl);

        // Fallback to default shadow color in case of image load error
        const defaultShadowColor = 'rgba(0, 0, 0, 0.5)';
        applyDropShadow([defaultShadowColor], headerElement);
    };
}

function extractBackgroundImageUrl(element) {
    const backgroundImage = getComputedStyle(element).backgroundImage;

    if (backgroundImage === 'none' || !backgroundImage) {
        return null;
    }

    const urlMatch = backgroundImage.match(/url\(["']?([^"']*)["']?\)/);
    if (urlMatch && urlMatch[1]) {
        return urlMatch[1]; // Return the clean URL
    }
    return null;
}

function applyDropShadow(colors, element) {
    // Create a multi-layered box shadow using the extracted colors
    const boxShadow = colors.map((color, index) => {
        const offset1 = 5;
        const offset2 = 1;
        return `${offset1}px ${offset2}px 1px 1px ${color}`;
    }).join(', ');

    // Apply the box-shadow to the element
    element.style.boxShadow = boxShadow;
    console.log('Applied drop shadow:', boxShadow);
}


// Create form component
function createPageForm() {
    // First, get the main container
    const mainContainer = document.querySelector('.main-container');
    if (!mainContainer) {
        console.error('Main container not found');
        return;
    }

    // Ensure main container is visible
    mainContainer.style.display = 'flex';
    
    // Clear main container content
    mainContainer.innerHTML = ''; // This ensures we start fresh

    // Create new form section
    const formSection = document.createElement('div');
    formSection.className = 'form-section';

    formSection.innerHTML = `
        <div class="form-container">
            <h2 class="form-title">Create New Page</h2>
            <div class="subheading">Create a new gated page.</div>

            <form id="pageForm">
                <div class="form-group">
                    <label class="form-label" for="title">Title</label>
                    <input 
                        type="text" 
                        id="title" 
                        name="title" 
                        class="form-input"
                        placeholder="Enter page title"
                        required
                    />
                </div>
                <div class="form-group">
                    <label class="form-label" for="description">Description</label>
                    <textarea 
                        id="description" 
                        name="description" 
                        class="form-input form-textarea"
                        placeholder="Enter page description"
                        required
                    ></textarea>
                </div>
                <button type="submit" class="form-button">
                    Create Page
                </button>
            </form>
        </div>
    `;

    // Append form section to main container
    mainContainer.appendChild(formSection);

    // Add form submission handler
    const pageForm = formSection.querySelector('#pageForm');
    pageForm.addEventListener('submit', async (e) => {
        e.preventDefault();

        try {
            // Get form data
            const stxAddress = userSession.loadUserData().profile.stxAddress.mainnet;
            const title = pageForm.querySelector('#title').value.trim();
            const description = pageForm.querySelector('#description').value.trim();
            const slug = title.toLowerCase().replace(/[^a-z0-9]+/g, '-');

            // Validate inputs
            if (!title || !description) {
                throw new Error('Please fill in all fields');
            }

            // Store form data in localStorage
            localStorage.setItem('pendingPage', JSON.stringify({
                title,
                description
            }));

            // Show confirmation popup
            showPublishConfirmation(title, slug, stxAddress, mainContainer);
        } catch (error) {
            showError(error.message, mainContainer);
        }
    });

    return formSection;
}

// Helper function to show errors
function showError(message, container) {
    const errorElement = document.createElement('div');
    errorElement.className = 'error-message';
    errorElement.textContent = message;

    // Remove any existing error messages
    const existingError = container.querySelector('.error-message');
    if (existingError) {
        existingError.remove();
    }

    // Insert error at the top of the form section
    const formSection = container.querySelector('.form-section');
    if (formSection) {
        formSection.insertBefore(errorElement, formSection.firstChild);
    }

    // Auto-remove error after 5 seconds
    setTimeout(() => {
        errorElement.remove();
    }, 5000);
}


// Create and modify the page list component
function createPageList() {
    const container = document.createElement('div');
    container.className = 'page-list';

    
    // Helper function to create URL-friendly slug
    function createSlug(title) {
        return title
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/(^-|-$)+/g, '');
    }

    // Helper function to copy to clipboard
    async function copyToClipboard(text) {
        try {
            await navigator.clipboard.writeText(text);
            return true;
        } catch (err) {
            console.error('Failed to copy:', err);
            return false;
        }
    }

   

    // Add global functions
    window.showPublishConfirmation = (title, slug, stxAddress) => {
        const modal = document.createElement('div');
        const pageUrl = `${stxAddress}.gated.so/${slug}`;

        modal.innerHTML = `
            <div class="modal-overlay"></div>
            <div class="modal">
                <h3 style="margin-bottom: 16px">Publish Page</h3>
                <p style="margin-bottom: 8px">Your page will be published at:</p>
                <div class="url-display" style="margin-bottom: 24px">
                    <span>${pageUrl}</span>
                </div>
                <div style="display: flex; gap: 8px; justify-content: flex-end">
                    <button onclick="closeModal()" class="btn btn-secondary">Cancel</button>
                <button onclick="publishPage('${title.replace(/'/g, "\\'")}', '${slug}', '${stxAddress}')" class="btn btn-publish">Publish</button>
                </div>
            </div>
        `;
        document.body.appendChild(modal);
    };

    window.publishPage = async (title, slug, stxAddress) => {
        try {

            // Get the description from localStorage
            const pendingPage = JSON.parse(localStorage.getItem('pendingPage'));
            const description = pendingPage.description;

            // Initialize Stacks.js
            const userSession = new UserSession();
            const userData = userSession.loadUserData();
            const senderAddress = userData.profile.stxAddress.testnet;
            const contract = 'ST1ZCYG0D3HCK2F7SY8VH9ZREB0JWCBSAPEQCTM17.shivering-blush-macaw';  // Replace with your contract address

            // Get page data (assuming you have these values)
            const title = slug;
            const metadataUri = `ipfs://${slug}`;   // Or however you construct URI
            const gatedFee = 1000000;


            // Get elements before the transaction
            const statusContainer = document.getElementById('status-container');
            const publishButton = document.querySelector('.btn-publish');
            const cancelButton = document.querySelector('.btn-secondary');

            // Function to update UI after transaction
            const updateUI = (txId) => {
                if (statusContainer) {
                    statusContainer.innerHTML = `
                    <div class="transaction-status">
                        <p class="status-title" style="font-weight: bold; margin-bottom: 12px">
                            Broadcast Blockchain Transaction!
                        </p>
                        <p class="status-details" style="margin-bottom: 8px">
                            Transaction ID:
                        </p>
                        <a href="https://explorer.hiro.so/txid/${txId}?chain=testnet" 
                           target="_blank"
                           class="transaction-link"
                           style="word-break: break-all; color: #0066cc; text-decoration: underline;">
                            ${txId}
                        </a>
                    </div>
                `;
                }

                if (publishButton) {
                    publishButton.innerHTML = 'Transaction Sent!';
                    publishButton.disabled = true;
                }

                if (cancelButton) {
                    cancelButton.innerHTML = 'Close';
                }
            };

            // Function call options
            const functionArgs = [
                stringAsciiCV(title),           // title
                stringAsciiCV(description),     // description
                stringAsciiCV(metadataUri)      // metadata-uri
            ];

            const postConditions =
                [Pc.principal(senderAddress).willSendEq(gatedFee).ustx()]
            


            // Make the contract call
            const options = {
                network: STACKS_TESTNET,
                contractAddress: contract.split('.')[0],
                contractName: contract.split('.')[1],
                functionName: 'add-page',
                functionArgs: functionArgs,
                postConditions: postConditions,
                onFinish: data => {
                    console.log('Transaction:', data);
                    updateUI(data.txId);
                    // Construct the correct URL
                    const baseUrl = `${window.location.protocol}//${stxAddress}.${window.location.host}`;
                    const pageUrl = `testing/#/${slug}`;

                    // Add a slight delay to allow the transaction UI to update
                    setTimeout(() => {
                        window.location.href = pageUrl;
                    }, 2000);
                }
            };

            await openContractCall(options);
           
           
    
        } catch (error) {
            console.error('Error publishing page:', error);
            alert('Failed to publish page. Please try again.');
        }
    };

    window.copyPageUrl = async (url) => {
        if (await copyToClipboard(url)) {
            const button = event.target;
            const originalText = button.textContent;
            button.textContent = 'Copied!';
            setTimeout(() => {
                button.textContent = originalText;
            }, 2000);
        }
    };

    window.unpublishPage = async (pageId) => {
        try {
            const { error } = await supabase
                .from('pages')
                .update({
                    status: 'draft',
                    updated_at: new Date().toISOString()
                })
                .eq('id', pageId);

            if (error) throw error;
            renderPages(); // Refresh the list

        } catch (error) {
            console.error('Error unpublishing page:', error);
            alert('Failed to unpublish page. Please try again.');
        }
    };

    return container;
}

class NFTAndPagesFetcher {
    constructor(userSession) {
        this.userSession = userSession;
        this.pages = [];
    }

    async fetchUserContentTestnet() {
        try {
            const userData = this.userSession.loadUserData();
            const userAddressTestnet = userData.profile.stxAddress.testnet;
            console.log('Fetching NFTs for testnet address:', userAddressTestnet);

            const nftHoldingsUrl = `https://stacks-node-api.testnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddressTestnet}&limit=50`;

            const holdingsResponse = await fetch(nftHoldingsUrl);
            const holdingsData = await holdingsResponse.json();

            if (holdingsData.results && holdingsData.results.length > 0) {
                const sortedNFTs = holdingsData.results.sort((a, b) => b.block_height - a.block_height);
                console.log(`Found ${sortedNFTs.length} NFTs total`);

                const processedContent = await Promise.all(sortedNFTs.map(async (nft) => {
                    const [contractAddress, assetName] = nft.asset_identifier.split('::');
                    const tokenId = nft.value && nft.value.repr ? parseInt(nft.value.repr.replace(/^u/, '')) : null;

                    if (!tokenId) {
                        console.log('Skipping NFT (likely BNS name) TESTNET:', nft.value);
                        return null;
                    }

                    const isGatedPage = assetName === 'gated-page';
                    console.log(`NFT ${tokenId} is${isGatedPage ? '' : ' not'} a gated page`);

                    if (isGatedPage) {
                        try {
                        
                            const options = {
                                contractAddress: 'ST1ZCYG0D3HCK2F7SY8VH9ZREB0JWCBSAPEQCTM17',
                                contractName: 'shivering-blush-macaw',
                                functionName: 'get-page',
                                functionArgs: [uintCV(tokenId)],
                                network: STACKS_TESTNET,
                                senderAddress: userAddressTestnet
                            };

                            const result = await fetchCallReadOnlyFunction(options);
                            console.log('Page data received:', result.value.value);

                            if (result && result.value) {
                                const pageData = result.value.value;  

                                // Convert owner data to principal
                                const address = pageData.owner.address;
                                const ownerAddress = address;

                                const page = {
                                    type: 'page',
                                    id: tokenId,
                                    owner: pageData.owner.value,
                                    active: pageData.active?.type === 3,
                                    title: pageData.title.value,
                                    description: pageData.description.value,
                                    metadataUri: pageData['metadata-uri']?.value.data || '',
                                    url: `https://${userAddressTestnet}.gated.so/${pageData.title?.data || tokenId}`,
                                    blockHeight: nft.block_height
                                };

                                console.log('Processed page:', page);
                                return page;
                            } else {
                                console.log('No page data found in result');
                                return null;
                            }
                        } catch (pageError) {
                            console.error('Error fetching page data TESTNET:', pageError);
                            return null;
                        }
                    }
                    return null;
                }));

                const validContent = processedContent.filter(item => item !== null);
                this.pages = validContent.filter(item => item && item.type === 'page');

                console.log(`Found ${this.pages.length} valid pages`);
                return {
                    pages: this.pages,
                    nfts: []
                };
            }

            console.log('No NFTs found');
            return {
                pages: [],
                nfts: []
            };

        } catch (error) {
            console.error('Error in fetchUserContent Testnet:', error);
            return {
                pages: [],
                nfts: []
            };
        }
    }

    async fetchUserContentMainnet() {
        const userSession = new UserSession();
        const userData = userSession.loadUserData();
        const userAddressMainnet = userData.profile.stxAddress.mainnet;
        const shortAddressMainnet = formatAccountNumber(userAddressMainnet);
        const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddressMainnet}&limit=50`;

        try {
            // Step 1: Fetch user's NFT holdings
            const holdingsResponse = await fetch(nftHoldingsUrl);
            const holdingsData = await holdingsResponse.json();

            console.log('Holdings data MAINNET:', holdingsData);

            if (holdingsData.results && holdingsData.results.length > 0) {
                // Sort NFTs by block height in descending order (most recent first)
                const sortedNFTs = holdingsData.results.sort((a, b) => b.block_height - a.block_height);

                const processedContent = await Promise.all(sortedNFTs.map(async (nft) => {
                    console.log(`Checking NFT MAINNET:`, nft);

                    const [contractAddress, assetName] = nft.asset_identifier.split('::');
                    const tokenId = nft.value && nft.value.repr ? parseInt(nft.value.repr.replace(/^u/, '')) : null;

                    if (!tokenId) {
                        console.log('Skipping NFT (likely BNS name):', nft.value);
                        return null;
                    }

                    // Check if this is a gated page NFT
                    const isGatedPage = assetName === 'gated-page'; // Update this to match your contract's NFT asset name

                    if (isGatedPage) {
                        // Fetch page details from your contract
                        try {
                            const pageDataUrl = `https://api.mainnet.hiro.so/extended/v1/contract/${contractAddress}/call-read/get-page`;
                            const pageResponse = await fetch(pageDataUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    arguments: [`u${tokenId}`]
                                })
                            });
                            const pageData = await pageResponse.json();

                            if (pageData.result && pageData.result.value) {
                                return {
                                    type: 'page',
                                    id: tokenId,
                                    title: pageData.result.value.title,
                                    description: pageData.result.value.description,
                                    url: `https://${userAddressMainnet}.gated.so/${pageData.result.value.title}`,
                                    active: pageData.result.value.active,
                                    blockHeight: nft.block_height
                                };
                            }
                        } catch (pageError) {
                            console.error('Error fetching page data Mainnet:', pageError);
                            return null;
                        }
                    } else {
                        // Handle regular NFTs as before
                        try {
                            const metadataUrl = `https://api.hiro.so/metadata/v1/nft/${contractAddress}/${tokenId}`;
                            console.log('Fetching metadata from Mainnet:', metadataUrl);

                            const metadataResponse = await fetch(metadataUrl);
                            const metadata = await metadataResponse.json();

                            console.log('Metadata Mainnet:', metadata);

                            const cachedImageUrl = metadata?.metadata?.cached_image;
                            if (cachedImageUrl && await this.isValidImageUrl(cachedImageUrl)) {
                                return {
                                    type: 'nft',
                                    id: tokenId,
                                    imageUrl: cachedImageUrl,
                                    name: metadata?.metadata?.name || `NFT #${tokenId}`,
                                    blockHeight: nft.block_height
                                };
                            }
                        } catch (metadataError) {
                            console.error('Error fetching metadata for NFT Mainnet:', metadataError);
                            return null;
                        }
                    }
                    return null;
                }));

                // Filter out null entries and separate pages from NFTs
                const validContent = processedContent.filter(item => item !== null);
                this.pages = validContent.filter(item => item.type === 'page');
                this.nfts = validContent.filter(item => item.type === 'nft');

                console.log('Fetched pages:', this.pages);
                console.log('Fetched NFTs:', this.nfts);

                return {
                    pages: this.pages,
                    nfts: this.nfts
                };
            }
        } catch (error) {
            console.error('Error in fetchUserContent Testnet:', error);
            console.error('Error stack Testnet:', error.stack);
            return {
                pages: [],
                nfts: []
            };
        }
    }

    // Your existing isValidImageUrl method
    async isValidImageUrl(url) {
        try {
            const response = await fetch(url, { method: 'HEAD' });
            return response.ok;
        } catch (error) {
            console.error('Error validating image URL:', error);
            return false;
        }
    }

    // Helper method to render pages
    renderPages(container) {
        if (!this.pages || !this.pages.length) {
            container.innerHTML = `
                <div class="empty-state">
                    <h3>No Pages Created Yet</h3>
                    <p>Start creating your first gated content page</p>
                    <button class="create-button" id="createFirstPageButton">
                        Create Your First Page
                    </button>
                </div>
            `;
            return;
        }
        const pagesHTML = this.pages.map(page => `

            <div class="page-card">
            
                
                <div class="page-card-content">
                    <div class="page-card-header">
                        <h3>${this.escapeHtml(page.title) || 'Untitled'}</h3>
                    </div>
                    <div class="page-card-description">
                        <p>${this.escapeHtml(page.description) || 'No description'}</p> 
                    </div>
                </div> 
                <div class="page-card-content-right">
                    <button class="icon-button" onclick="window.open('${this.escapeHtml(page.url)}', '_blank')">
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                        <polyline points="15 3 21 3 21 9"></polyline>
                        <line x1="10" y1="14" x2="21" y2="3"></line>
                    </svg>
                </button>
                </div>
            </div>
        `).join('');

        container.innerHTML = `
            <div class="pages-grid">
                ${pagesHTML}
            </div>
        `;
    }


    escapeHtml(unsafe) {
        if (!unsafe) return '';
        return unsafe
            .toString()
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;");
    }
}

window.userSession = new UserSession({
    appConfig: new AppConfig(['store_write', 'publish_data'])
});

// RUN WHEN PAGE LOADS
document.addEventListener('DOMContentLoaded', () => {
    // const formContainer = document.querySelector('.form-container') || document.body;
    // formContainer.appendChild(createPageForm());
    
    // const listContainer = document.body;
    // listContainer.appendChild(createPageList());
    
    // getBNSv2()

    // Create NFTAndPagesFetcher instance
   // const nftFetcher = new NFTAndPagesFetcher(userSession);

    // Initialize router with the nftFetcher
    
    //renderStoreItems();
    const profilePhoto = new ProfilePhoto();

    // Make it globally accessible if needed
    window.profilePhoto = profilePhoto;

    window.walletDropdown = new WalletDropdown();


    //CONNECT TO STACKS WALLET
    const connectWalletButton = document.getElementById('connectWallet-button');

    if (connectWalletButton) {
        connectWalletButton.addEventListener('click', () => {

            if (userSession.isUserSignedIn()) {
                const userData = userSession.loadUserData();
                const userAddressTestnet = userData.profile.stxAddress.testnet;
                const userAddressMainnet = userData.profile.stxAddress.mainnet;
                const shortAddressMainnet = formatAccountNumber(userAddressMainnet);
                const shortAddressTestnet = formatAccountNumber(userAddressTestnet);

                connectWalletButton.textContent = shortAddressMainnet;
                connectWalletButton.textContent = shortAddressTestnet;
                if (userAddressMainnet) {
                    console.log('STX Address MAINNET:', userData.profile.stxAddress.mainnet);
                }

                if (userAddressTestnet) {
                    console.log('STX Address TESTNET:', userData.profile.stxAddress.testnet);
                }
            } else {
                console.log('User is not signed in.');
            }

            showConnect({
                appDetails: {
                    name: 'GATED',
                    icon: gatedLogo,
                },
                redirectTo: '/',
                onFinish: () => {
                    // Handle successful authentication
                    const userData = userSession.loadUserData();
                    const userAddressTestnet = userData.profile.stxAddress.testnet;
                    const userAddressMainnet = userData.profile.stxAddress.mainnet;
                    const shortAddressMainnet = formatAccountNumber(userAddressMainnet);
                    const shortAddressTestnet = formatAccountNumber(userAddressTestnet);
                    console.log('Authenticated as:', userData);
                    console.log('STX Address Mainnet:', shortAddressMainnet);  // Outputs: 1234****3456
                    console.log('STX Address Testnet:', shortAddressTestnet);  // Outputs: 1234****3456

                    // Update button text or disable it after successful connection
                    connectWalletButton.textContent = shortAddressMainnet;
                    connectWalletButton.textContent = shortAddressTestnet;

                    connectWalletButton.disabled = true;
                    //   checkAccess(userAddress, 'SP1AY6K3PQV5MRT6R4S671NWW2FRVPKM0BR162CT6.leo-token::leo');
                    // if (checkAccess) {
                    //     console.log("User is Authenticated & Access has been granted");
                    //     triggerBlinkGreen();
                    // }
                },
                userSession: userSession,
            });
        });


    } else {
        console.error('Connect Wallet button not found');
    }

    // // PAY IN WELSH
    // CONNECT TO STACKS WALLET
    // const connectWalletButton2 = document.getElementById('connectWallet-button2');

    // if (connectWalletButton2) {
    //     connectWalletButton2.addEventListener('click', () => {

    //         if (userSession.isUserSignedIn()) {
    //             const userData = userSession.loadUserData();
    //             console.log('STX Address:', userData.profile.stxAddress.mainnet);
    //             stakeTokens()
    //         } else {
    //             console.log('User is not signed in.');
    //             showConnect({
    //                 appDetails: {
    //                     name: 'GATED',
    //                     icon: window.location.origin + '/my-app-logo.png',
    //                 },
    //                 redirectTo: '/',
    //                 onFinish: () => {
    //                     // Handle successful authentication
    //                     const userData = userSession.loadUserData();
              

    //                     if (userData.profile.stxAddress.testnet) {
    //                         const userAddressTestnet = userData.profile.stxAddress.testnet;
    //                         const shortAddressTestnet = formatAccountNumber(userAddressTestnet);
    //                     };

    //                     if (userData.profile.stxAddress.mainnet) {
    //                         const userAddressMainnet = userData.profile.stxAddress.mainnet;
    //                         const shortAddressMainnet = formatAccountNumber(userAddressMainnet);
    //                     };

                      
                  
    //                     console.log('Authenticated as:', userData);
    //                     console.log('STX Address Mainnet:', shortAddressMainnet);  // Outputs: 1234****3456
    //                     console.log('STX Address Testnet:', shortAddressTestnet);  // Outputs: 1234****3456

    //                     // Update button text or disable it after successful connection
    //                     connectWalletButton2.textContent = shortAddressMainnet;
    //                     connectWalletButton.textContent = shortAddressMainnet;
    //                     connectWalletButton2.textContent = shortAddressTestnet;
    //                     connectWalletButton.textContent = shortAddressTestnet;
    //                     connectWalletButton2.disabled = true;

    //                 },
    //                 userSession: userSession,
    //             });
    //         }


    //     });


    // } else {
    //     console.error('Connect Wallet button not found');
    // }


    function processPayment() {
        var paymentMethod = document.getElementById('payment-method').value;
        console.log("Selected payment method:", paymentMethod); // Debugging: Check the payment method in the console.

        switch (paymentMethod) {
            case 'STX':
                alert("Processing payment with STX...");
                // Add your logic here for STX payment processing
                break;
            case 'WELSH':

                alert("Processing payment with WELSH...");
                // Add your logic here for WELSH payment processing
                break;
            default:
                alert("Unsupported payment method.");
                return; // Exit the function if the payment method is not supported
        }

        // Simulate a payment process delay and close the overlay after payment
        setTimeout(() => {
            document.getElementById('overlay').style.display = 'none';
            document.getElementById('popupContainer').style.display = 'none';
            alert("Payment processed successfully!");
        }, 1000); // Delay of 1000 milliseconds (1 second) for demonstration
    }
});

let sections = [];

function renderSections() {
    const container = document.getElementById('sections-container');
    container.innerHTML = '';
    sections.forEach((section, index) => {
        const sectionElement = document.createElement('div');
        sectionElement.className = 'bg-white shadow-md rounded-lg p-6';
        sectionElement.innerHTML = `
                    <h2 class="text-2xl font-semibold text-gray-900">${section.title}</h2>
                    <p class="mt-2 text-lg text-gray-600">${section.subtitle}</p>
                    <p class="mt-4 text-sm text-gray-500">This content is gated. ${section.gateType === 'nft' ? 'Own this NFT' : `Hold ${section.gateRequirement} tokens`} to unlock.</p>
                `;
        container.appendChild(sectionElement);
    });
}


function createContentItem(item) {
    const contentDiv = document.createElement('div');
    contentDiv.className = 'content-item';

    const title = document.createElement('div');
    title.className = 'container-title';
    title.textContent = item.title;
    contentDiv.appendChild(title);

    const description = document.createElement('div');
    description.className = 'container-description';
    description.textContent = item.description;
    contentDiv.appendChild(description);

    if (item.isMusicPlayer) {
        const musicPlayerCard = createMusicPlayerCard(item);
        contentDiv.appendChild(musicPlayerCard);
    } else if (item.isMultiNFT) {
        const multiNFTContainer = document.createElement('div');
        multiNFTContainer.className = 'multi-nft-container';

        item.nfts.forEach(nft => {
            const nftCard = createNFTCard(nft);
            multiNFTContainer.appendChild(nftCard);
        });

        contentDiv.appendChild(multiNFTContainer);
    } else if (item.isTokenGated || item.isSellable) {

        // Add token logos here, outside of any conditional blocks
        if (item.requiredTokens) {
            const tokenLogos = document.createElement('div');
            tokenLogos.className = 'token-logos';
            tokenLogos.style.display = 'flex';
            tokenLogos.style.justifyContent = 'center';
            tokenLogos.style.gap = '10px';
            tokenLogos.style.margin = '10px 0';

            item.requiredTokens.forEach(token => {
                const logoContainer = document.createElement('div');
                logoContainer.style.textAlign = 'center';

                const logo = document.createElement('img');
                logo.src = `/logos/${token.toLowerCase()}.png`; // Ensure you have these logo images
                logo.alt = token;
                logo.width = 40;
                logo.height = 40;

                const tokenName = document.createElement('p');
                tokenName.textContent = token;
                tokenName.style.margin = '5px 0 0 0';
                tokenName.style.fontSize = '12px';

                logoContainer.appendChild(logo);
                logoContainer.appendChild(tokenName);
                tokenLogos.appendChild(logoContainer);
            });

            contentDiv.appendChild(tokenLogos);
        }

        if (item.isSellable) {
            // Sellable item 
            const sellableItem = document.createElement('div');
            sellableItem.className = 'sellable-item';

            const itemLabel = document.createElement('div');
            itemLabel.className = 'item-label';
            itemLabel.innerHTML = '<img src="/roo.png" width="50px" style="filter: blur(5px)" ><p> STX NFT </p>';

            const buyButton = document.createElement('button');
            buyButton.className = 'buy-button';
            buyButton.id = 'buy-button';
            buyButton.innerHTML = "5 WELSH";

            sellableItem.appendChild(itemLabel);
            sellableItem.appendChild(buyButton);
            contentDiv.appendChild(sellableItem);

            // Check if the button exists before adding the event listener
            if (buyButton) {
                buyButton.addEventListener('click', function () {
                    if (userSession.isUserSignedIn()) {
                        document.getElementById('overlay').style.display = 'block';
                        document.getElementById('popupContainer').style.display = 'block';
                    } else {
                        document.getElementById('overlay').style.display = 'block';
                        document.getElementById('popupContainer').style.display = 'block';
                        triggerBlinkRed();
                    }
                });
            } else {
                console.error('Buy button not found in the document.');
            }

        } else if (item.isTokenGated) {
            const conditionsWrapper = document.createElement('div');
            conditionsWrapper.className = 'conditions-wrapper';
            conditionsWrapper.innerHTML = `
            <i class="fas fa-lock"></i>
            <span>How to unlock</span>
        `;

            const conditionsPopup = document.createElement('div');
            conditionsPopup.className = 'conditions-popup';
            conditionsPopup.innerHTML = `
            <h4>Unlock Conditions</h4>
            <ul class="conditions-list">
                ${item.unlockConditions.map(condition => `
                    <li>
                        ${condition.type === 'tokenHolding' ? `
                            <img src="/logos/${condition.tokens[0].toLowerCase()}.png" alt="${condition.tokens[0]}" class="token-icon">
                            <span>Hold ${condition.tokens.join(', ')} tokens</span>
                        ` : ''}
                        ${condition.type === 'minimumBalance' ? `
                            <svg class="balance-icon" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="currentColor" d="M21,18V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V6H12C10.89,6 10,6.9 10,8V16A2,2 0 0,0 12,18H21M12,16H22V8H12V16M16,13.5A1.5,1.5 0 0,1 14.5,12A1.5,1.5 0 0,1 16,10.5A1.5,1.5 0 0,1 17.5,12A1.5,1.5 0 0,1 16,13.5Z" />
                            </svg>
                            <span>Minimum balance of ${condition.amount} ${condition.currency}</span>
                        ` : ''}
                    </li>
                `).join('')}
            </ul>
        `;
            conditionsWrapper.appendChild(conditionsPopup);
            contentDiv.appendChild(conditionsWrapper);

            const tokenGatedDiv = document.createElement('div');
            tokenGatedDiv.className = 'token-gated-content';

            tokenGatedDiv.style.position = 'relative';
            tokenGatedDiv.style.overflow = 'hidden';
            tokenGatedDiv.style.backgroundColor = '#f0f0f0';
            tokenGatedDiv.style.padding = '20px';
            tokenGatedDiv.style.borderRadius = '5px';

            const blurredContent = document.createElement('div');
            blurredContent.style.filter = 'blur(5px)';
            blurredContent.innerHTML = `
            <h3>${item.gatedContent.title}</h3>
            <p>${item.gatedContent.description}</p>
        `;

            const unlockButton = document.createElement('button');
            unlockButton.className = 'unlock-button';
            unlockButton.textContent = 'VERIFY TOKEN';
            unlockButton.addEventListener('click', function () {
                if (typeof userSession !== 'undefined' && typeof userSession.isUserSignedIn === 'function') {
                    if (userSession.isUserSignedIn()) {
                        document.getElementById('overlay').style.display = 'block';
                        document.getElementById('popupContainer2').style.display = 'block';
                    } else {
                        document.getElementById('overlay').style.display = 'block';
                        document.getElementById('popupContainer2').style.display = 'block';
                        if (typeof triggerBlinkRed === 'function') {
                            triggerBlinkRed();
                        }
                    }
                } else {
                    console.error('userSession is not defined or does not have isUserSignedIn method');
                }
            });

            // Display unlock conditions
            const conditionsDiv = document.createElement('div');
            conditionsDiv.className = 'unlock-conditions';
            conditionsDiv.innerHTML = '<h4>Unlock Conditions:</h4>';
            item.unlockConditions.forEach(condition => {
                const conditionText = document.createElement('p');
                switch (condition.type) {
                    case 'tokenHolding':
                        conditionText.textContent = `Hold ${condition.tokens.join(', ')} tokens`;
                        break;
                    case 'minimumBalance':
                        conditionText.textContent = `Minimum balance of ${condition.amount} ${condition.currency}`;
                        break;
                    case 'nftOwnership':
                        conditionText.textContent = `Own NFT: ${condition.nftId}`;
                        break;
                }
                conditionsDiv.appendChild(conditionText);
            });

            tokenGatedDiv.appendChild(blurredContent);
            tokenGatedDiv.appendChild(unlockButton);
            contentDiv.appendChild(tokenGatedDiv);

            // Event listeners for desktop hover and mobile touch
            conditionsWrapper.addEventListener('mouseenter', () => {
                if (window.innerWidth > 768) { // Desktop
                    conditionsPopup.style.display = 'block';
                }
            });

            conditionsWrapper.addEventListener('mouseleave', () => {
                if (window.innerWidth > 768) { // Desktop
                    conditionsPopup.style.display = 'none';
                }
            });

            conditionsWrapper.addEventListener('click', (e) => {
                if (window.innerWidth <= 768) { // Mobile
                    e.preventDefault();
                    conditionsPopup.style.display = conditionsPopup.style.display === 'block' ? 'none' : 'block';
                }
            });

            // Close popup when clicking outside on mobile
            document.addEventListener('click', (e) => {
                if (window.innerWidth <= 768 && !conditionsWrapper.contains(e.target)) {
                    conditionsPopup.style.display = 'none';
                }
            });
        }
    }
    return contentDiv;
}

export function renderStoreItems(items = []) {
    const container = document.getElementById('store-container');
    if (container) {
        console.log('Store container found');

        // Clear existing content first
        container.innerHTML = '';


        items.forEach((item, index) => {
            console.log(`Processing item ${index}:`, item.title);
            if (item.isMusicPlayer) {
                console.log('Creating music player for:', item.title);
                const musicPlayerCard = createMusicPlayerCard(item);
                container.appendChild(musicPlayerCard);
            }
        });
    } else {
        console.error('Store container not found');
    }
}

export { createMusicPlayerCard, togglePlayPause, loadTrack, playPreviousTrack, playNextTrack, handleMint };
