// musicConfig.js
import track1Image from './audio/Josie_image.png';
import track1Audio from './audio/Josie_Field_RADIO_SILENCE.mp3';
import track2Image from './audio/Ard_image.png';
import track2Audio from './audio/Ard_Matthews_YOU_MAKE_IT_ALRIGHT.mp3';
import track3Image from './audio/Arno_image.png';
import track3Audio from './audio/Arno_Carstens_MORTALS.mp3';
import track4Image from './audio/Kahn_image.png';
import track4Audio from './audio/Kahn_Morbee_BUZZ_HEAD.mp3';
import track5Image from './audio/Laurie_image.png';
import track5Audio from './audio/Laurie_Levine_GREY.mp3';
import track6Image from './audio/Jacques_image2.png';
import track6Audio from './audio/Jacques_Moolman_EXILE.mp3';
import track7Image from './audio/Evert_Image.png';
import track7Audio from './audio/Evert_Snyman_I_SHOULDNT_BREAK.mp3';
import track8Image from './audio/Xander_image.png';
import track8Audio from './audio/Xander_BBQ_TO_BRAAI.mp3';
import albumImage from '/audio/Album_image.png';

// Player state and functions

export const musicPlayer = {
    currentTrackIndex: 0,
    audioPlayer: new Audio(),
    isInitialized: false,
    elements: null,
    MAX_PLAY_TIME: 30,
    audioTimer: null,
    countdownInterval: null,
    currentItem: null, // Store current musicData item
    stickyPlayer: null,


    init() {
        console.log('Initializing music player');
        if (this.isInitialized) {
            console.log('Already initialized');
            return;
        }

        // Initialize sticky player reference
        this.stickyPlayer = document.getElementById('music-player-sticky');


        if (this.stickyPlayer) {
            // Initialize play/pause button
            const playPauseBtn = this.stickyPlayer.querySelector('#play-pause-btn');
            if (playPauseBtn) {
                playPauseBtn.addEventListener('click', () => this.togglePlayPause());
            }

            // Initialize prev/next buttons
            const prevBtn = this.stickyPlayer.querySelector('#prev-btn');
            const nextBtn = this.stickyPlayer.querySelector('#next-btn');
            if (prevBtn) prevBtn.addEventListener('click', () => this.previousTrack());
            if (nextBtn) nextBtn.addEventListener('click', () => this.nextTrack());

            // Initialize progress bar
            const progressBar = this.stickyPlayer.querySelector('#progress-bar');
            if (progressBar) {
                progressBar.addEventListener('input', (e) => {
                    const time = (e.target.value / 100) * this.MAX_PLAY_TIME;
                    this.audioPlayer.currentTime = time;
                });
            }

            // Initialize volume control
            const volumeControl = this.stickyPlayer.querySelector('#volume-control');
            if (volumeControl) {
                volumeControl.addEventListener('input', (e) => {
                    this.audioPlayer.volume = e.target.value;
                });
            }
        }

        // Add timeupdate event listener to handle 30-second limit
        this.audioPlayer.addEventListener('timeupdate', () => {
            if (this.audioPlayer.currentTime >= this.MAX_PLAY_TIME) {
                this.audioPlayer.pause();
                this.updatePlayPauseButtons(false);
                this.resetAudioTimer();
                this.clearCountdown();
                this.resetDurationDisplay();
            }
            this.updateProgress();

        });

        this.isInitialized = true;
        this.currentTrackIndex = 0;
        console.log('Music player initialized');
    },
    // Let's also make sure the updateStickyPlayerInfo method is comprehensive
    updateStickyPlayerInfo() {
        if (!this.stickyPlayer || !this.currentItem) return;

        const currentSong = this.currentItem.songs[this.currentTrackIndex];
        const trackInfo = this.stickyPlayer.querySelector('#track-info');
        const albumArt = this.stickyPlayer.querySelector('#album-art');
        const songDuration = this.stickyPlayer.querySelector('#song-duration');
        const progressBar = this.stickyPlayer.querySelector('#progress-bar');

        if (trackInfo) trackInfo.textContent = `${currentSong.songArtist} - ${currentSong.songName}`;
        if (albumArt) {
            albumArt.src = currentSong.imgUrl;
            albumArt.alt = `${currentSong.songArtist} - ${currentSong.songName}`;
        }
        if (songDuration) songDuration.textContent = currentSong.duration;
        if (progressBar) progressBar.value = 0; // Reset progress bar when changing tracks

        // Log to verify the update
        console.log('Updated sticky player info:', {
            artist: currentSong.songArtist,
            song: currentSong.songName,
            image: currentSong.imgUrl,
            duration: currentSong.duration
        });
    },

    updateProgress() {
        const progressBar = this.stickyPlayer.querySelector('#progress-bar');
        if (progressBar) {
            const percentage = (this.audioPlayer.currentTime / this.MAX_PLAY_TIME) * 100;
            progressBar.value = percentage;
        }
    },

    previousTrack() {
        if (this.currentTrackIndex > 0) {
            const wasPlaying = !this.audioPlayer.paused;
            this.setCurrentTrack(this.currentTrackIndex - 1);
            this.loadTrack(this.currentTrackIndex, this.currentItem);
            this.updateStickyPlayerInfo(); // Add this line to update sticky player
            if (wasPlaying) {
                this.audioPlayer.play().then(() => {
                    this.startAudioTimer();
                    this.startCountdown();
                    this.updatePlayPauseButtons(true);
                });
            }
        }
    },



    nextTrack() {
        if (this.currentItem && this.currentTrackIndex < this.currentItem.songs.length - 1) {
            const wasPlaying = !this.audioPlayer.paused;
            this.setCurrentTrack(this.currentTrackIndex + 1);
            this.loadTrack(this.currentTrackIndex, this.currentItem);
            this.updateStickyPlayerInfo(); // Add this line to update sticky player
            if (wasPlaying) {
                this.audioPlayer.play().then(() => {
                    this.startAudioTimer();
                    this.startCountdown();
                    this.updatePlayPauseButtons(true);
                });
            }
        }
    },


    setCurrentTrack(index) {
        console.log('Setting current track to:', index);
        this.currentTrackIndex = index;
    },

    loadTrack(index, item) {
        console.log('Loading track:', index, item.songs[index]);
        this.currentItem = item; // Store the current item
        const song = item.songs[index];
        this.audioPlayer.src = song.audioUrl;
        this.audioPlayer.load();
        console.log('Track loaded, audio src:', this.audioPlayer.src);
        this.updatePlayerInfo(song);
        this.updateActiveTrack(index);
        this.resetDurationDisplay(); // Reset duration when loading new track
        this.updateStickyPlayerInfo(); // Add this line to ensure sticky player is always updated

    },

    updateClaimButtonPosition() {
        const claimButton = document.getElementById('claimButton');
        if (claimButton) {  // Removed the display check to always update position
            if (this.stickyPlayer && this.stickyPlayer.style.display === 'flex') {
                const playerHeight = this.stickyPlayer.offsetHeight;
                claimButton.style.bottom = `${playerHeight + 20}px`;
                claimButton.style.zIndex = '1000'; // Ensure it's above sticky player
            } else {
                claimButton.style.bottom = '20px';
            }
        }
    },

    showStickyPlayer() {
        if (this.stickyPlayer) {
            this.stickyPlayer.style.display = 'flex';
            this.stickyPlayer.style.zIndex = '999'; // Ensure it's below claim button
            this.updateStickyPlayerInfo();
            this.updateClaimButtonPosition();
        }
    },


    hideStickyPlayer() {
        if (this.stickyPlayer) {
            this.stickyPlayer.style.display = 'none';
            this.updateClaimButtonPosition();

        }
    },


    togglePlayPause() {
        console.log('Toggle play/pause, currently paused:', this.audioPlayer.paused);
        if (this.audioPlayer.paused) {
            if (this.audioPlayer.currentTime >= this.MAX_PLAY_TIME) {
                this.audioPlayer.currentTime = 0;
            }

            console.log('Playing audio');
            this.audioPlayer.play().then(() => {
                this.startAudioTimer();
                this.startCountdown();
                this.updatePlayPauseButtons(true);
                this.showStickyPlayer(); // Show sticky player when playing
            }).catch(error => {
                console.error('Error playing audio:', error);
                this.showErrorToUser("Failed to play audio. Please try again.");
            });
        } else {
            console.log('Pausing audio');
            this.audioPlayer.pause();
            this.resetAudioTimer();
            this.clearCountdown();
            this.resetDurationDisplay();
            this.updatePlayPauseButtons(false);
            // Sticky player remains visible when paused
        }
    },


    stopPlayback() {
        this.audioPlayer.pause();
        this.audioPlayer.currentTime = 0;
        this.updatePlayPauseButtons(false);
        this.resetAudioTimer();
        this.clearCountdown();
        this.resetDurationDisplay();
        this.hideStickyPlayer();
    },

    startCountdown() {
        this.clearCountdown();

        this.countdownInterval = setInterval(() => {
            const timeLeft = Math.ceil(this.MAX_PLAY_TIME - this.audioPlayer.currentTime);
            this.updateCountdownDisplay(timeLeft);

            if (timeLeft <= 0) {
                this.clearCountdown();
                this.resetDurationDisplay();
            }
        }, 1000);
    },

    clearCountdown() {
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
            this.countdownInterval = null;
        }
    },

    updateCountdownDisplay(timeLeft) {
        const songItems = document.querySelectorAll('.song-list li');
        const currentSongItem = songItems[this.currentTrackIndex];

        if (currentSongItem) {
            const durationEl = currentSongItem.querySelector('.song-duration');
            if (durationEl) {
                const minutes = Math.floor(timeLeft / 60);
                const seconds = timeLeft % 60;
                durationEl.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
            }
        }

        const currentSongDuration = document.querySelector('.current-song .song-duration');
        if (currentSongDuration) {
            const minutes = Math.floor(timeLeft / 60);
            const seconds = timeLeft % 60;
            currentSongDuration.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
        }
    },

    resetDurationDisplay() {
        if (!this.currentItem) return;

        const songItems = document.querySelectorAll('.song-list li');
        songItems.forEach((item, index) => {
            const durationEl = item.querySelector('.song-duration');
            if (durationEl && this.currentItem.songs[index]) {
                durationEl.textContent = this.currentItem.songs[index].duration;
            }
        });

        const currentSongDuration = document.querySelector('.current-song .song-duration');
        if (currentSongDuration && this.currentItem.songs[this.currentTrackIndex]) {
            currentSongDuration.textContent = this.currentItem.songs[this.currentTrackIndex].duration;
        }
    },


    updatePlayPauseButtons(isPlaying) {
        const buttons = document.querySelectorAll('#album-play-pause-btn, #play-pause-btn, #sticky-play-pause-btn');
        buttons.forEach(btn => {
            if (btn) {
                btn.innerHTML = isPlaying ?
                    '<i class="fa-solid fa-pause"></i>' :
                    '<i class="fa-solid fa-play"></i>';
            }
        });
    },


    startAudioTimer() {
        this.resetAudioTimer();

        this.audioTimer = setTimeout(() => {
            console.log('Time limit reached');
            this.audioPlayer.pause();
            this.updatePlayPauseButtons(false);
            this.clearCountdown();
            this.resetDurationDisplay();
        }, this.MAX_PLAY_TIME * 1000);
    },

    resetAudioTimer() {
        if (this.audioTimer) {
            clearTimeout(this.audioTimer);
            this.audioTimer = null;
        }
    },

    updatePlayerInfo(song) {
        console.log('Updating player info:', song);
        const songName = document.querySelector('.song-name');
        const songArtist = document.querySelector('.song-artist');
        if (songName) songName.textContent = song.songName;
        if (songArtist) songArtist.textContent = song.songArtist;
    },

    updateActiveTrack(index) {
        console.log('Updating active track:', index);
        const tracks = document.querySelectorAll('.song-list li');
        tracks.forEach(track => track.classList.remove('active-track'));
        if (tracks[index]) {
            tracks[index].classList.add('active-track');
        }
    }
};

export const musicData = {
    title: "When Walls Break",
    subtitle: "Various Artists",
    albumImage: albumImage,
    description: "Welcome to Blocktune. At Blocktune, we’re pioneering a new way to release and experience music.Through the power of Stacks, we empower musicians to forge a new path for music distribution.",
    isMusicPlayer: true,
    monthlyListeners: 617,
    albumPrice: 80,
    albumSupply: 100,
    albumCoverArtist: "Reece Swanepoel",
    songs: [ 
        { songArtist: "Josie Field", songName: "Radio Silence", duration: "3:29", audioUrl: track1Audio, imgUrl: track1Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
        { songArtist: "Ard Matthews", songName: "You Make it Alright", duration: "4:10", audioUrl: track2Audio, imgUrl: track2Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
        { songArtist: "Arno Carstens", songName: "Mortals", duration: "4:43", audioUrl: track3Audio, imgUrl: track3Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
        { songArtist: "Kahn Morbee", songName: "Buzz Head", duration: "2:35", audioUrl: track4Audio, imgUrl: track4Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
        { songArtist: "Laurie Levine", songName: "Grey", duration: "4:54", audioUrl: track5Audio, imgUrl: track5Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
        { songArtist: "Jacques Moolman", songName: "Exile", duration: "4:23", audioUrl: track6Audio, imgUrl: track6Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
        { songArtist: "Evert Synman", songName: "I Shouldn't Break", duration: "3:08", audioUrl: track7Audio, imgUrl: track7Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 },
        { songArtist: "Xander", songName: "BBQ to Braai", duration: "1:48", audioUrl: track8Audio, imgUrl: track8Image, songPrice: "3", imgArtist: "Teflon Musk", songSupply: 100 }
    ]
};