import { AuthService } from '/auth-service.js';
import { UserSession } from '@stacks/auth';
import { AppConfig, showConnect, openContractCall } from '@stacks/connect';
import { STACKS_MAINNET, STACKS_TESTNET } from '@stacks/network';
import { bufferCV, addressToString, Cl, deserializePrincipal, standardPrincipalCVFromAddress, intCV, uintCV, PostConditionMode, stringAsciiCV, principalCV, standardPrincipalCV, FungibleConditionCode, makeContractCall, broadcastTransaction, fetchCallReadOnlyFunction, READONLY_FUNCTION_CALL_PATH, cvToJSON, Pc } from '@stacks/transactions';
import WalletDropdown from './walletDropdown.js';
import gatedLogo from '/gated-logo2.png';
import blocktuneLogo from '/blocktune-logo.jpg';

import roo from '/roo.png';
import { musicData, musicPlayer } from './musicConfig.js';

import { ProfilePhoto, renderStoreItems, fetchUserNFTs, handleMint, createMusicPlayerCard, togglePlayPause, loadTrack, playPreviousTrack, playNextTrack } from './script.js';  // Add this import
import track1Image from './audio/Josie_image.png';
import track1Audio from './audio/Josie_Field_RADIO_SILENCE.mp3';
import track2Image from './audio/Ard_image.png';
import track2Audio from './audio/Ard_Matthews_YOU_MAKE_IT_ALRIGHT.mp3';
import track3Image from './audio/Arno_image.png';
import track3Audio from './audio/Arno_Carstens_MORTALS.mp3';
import track4Image from './audio/Kahn_image.png';
import track4Audio from './audio/Kahn_Morbee_BUZZ_HEAD.mp3';
import track5Image from './audio/Laurie_image.png';
import track5Audio from './audio/Laurie_Levine_GREY.mp3';
import track6Image from './audio/Jacques_image2.png';
import track6Audio from './audio/Jacques_Moolman_EXILE.mp3';
import track7Image from './audio/Evert_Image.png';
import track7Audio from './audio/Evert_Snyman_I_SHOULDNT_BREAK.mp3';
import track8Image from './audio/Xander_image.png';
import track8Audio from './audio/Xander_BBQ_TO_BRAAI.mp3';
import albumImage from '/audio/Album_image.png';


window.app = {
  showCreatePageModal: null,  // Initialize as null
  hideCreatePageModal: null   // Initialize as null
};

function sanitizeUrl(title) {
  return title
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/%20/g, '-') // Replace %20 with hyphens
    .replace(/[^a-z0-9-]/g, '') // Remove special characters
    .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
    .replace(/^-|-$/g, ''); // Remove leading/trailing hyphens
  
}



export class Router {


  constructor(nftFetcher) {
  

    this.routes = [];
    this.currentStxAddress = null;
    this.mainContent = document.getElementById('main-content');
  
    // Bind the methods to this instance
    window.app.showCreatePageModal = () => this.showCreatePageModal();
    window.app.hideCreatePageModal = () => this.hideCreatePageModal();

    try {
      // Initialize auth first
      console.log('Creating AuthService');
      this.auth = new AuthService(this);
      

      // Then check for existing session
      this.checkExistingSession();

      console.log('AuthService created:', this.auth);
    } catch (error) {
      console.error('Failed to create AuthService:', error);
    }

    // Initialize route handlers immediately
    this.initializeRouteHandlers();

    // Wait for DOM to be fully loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }
  
    console.log('AuthService created:', this.auth);


    // Handle route changes
    window.addEventListener('hashchange', () => this.handleRoute());
    window.addEventListener('popstate', () => this.handleRoute());

    // Setup global wallet functions
    window.connectWallet = async (isChange = false) => {
      try {
        await this.auth.connectWallet(isChange);
        this.handleRoute();
      } catch (error) {
        console.error('Failed to connect wallet:', error);
      }
    };

    window.disconnectWallet = () => {
      this.auth.disconnectWallet();
    };
    console.log('Router constructor completed');

  }

  init() {
    // First check if element exists
    this.mainContent = document.getElementById('main-container');

    // If it doesn't exist, create it
    if (!this.mainContent) {
      this.mainContent = document.createElement('div');
      this.mainContent.id = 'main-container';
      document.body.appendChild(this.mainContent);
    }

    // Now handle the initial route
    this.handleRoute();
  }
  initializeRouteHandlers() {
    this.routeHandlers = {
      home: {
        match: (parsedUrl) => {
          const matches = !parsedUrl.stxAddress && !parsedUrl.pageTitle;
          console.log('Home match check:', {
            hasStxAddress: !!parsedUrl.stxAddress,
            hasPageTitle: !!parsedUrl.pageTitle,
            matches
          });
          return matches;
        },
        render: () => this.renderHomePage()
      },
      dashboard: {
        match: (parsedUrl) => {
          const stxMatches = parsedUrl.stxAddress?.toLowerCase() === this.currentStxAddress?.toLowerCase();
          const noPageTitle = !parsedUrl.pageTitle;
          console.log('Dashboard match check:', {
            stxMatches,
            noPageTitle,
            currentStx: this.currentStxAddress,
            parsedStx: parsedUrl.stxAddress
          });
          return stxMatches && noPageTitle;
        },
        render: (stxAddress) => this.renderAdminDashboard(stxAddress)
      },
      claim: {
        match: (parsedUrl) => {
          const stxMatches = parsedUrl.stxAddress?.toLowerCase() !== this.currentStxAddress?.toLowerCase();
          const noPageTitle = !parsedUrl.pageTitle;
          console.log('Claim match check:', {
            stxMatches,
            noPageTitle,
            currentStx: this.currentStxAddress,
            parsedStx: parsedUrl.stxAddress
          });
          return parsedUrl.stxAddress && noPageTitle && stxMatches;
        },
        render: (stxAddress) => this.renderClaimPage(stxAddress)
      },
      specificPage: {
        match: (parsedUrl) => {
          const isSpecificPage = parsedUrl.pageTitle?.toLowerCase() === 'when-walls-break-test-1';
          const hasStxAddress = !!parsedUrl.stxAddress;
          return hasStxAddress && isSpecificPage;
        },
        render: async (stxAddress, pageTitle) => {
          try {

            if (!window.musicPlayer?.isInitialized) {
              window.musicPlayer = { ...musicPlayer };  // Create new instance
              window.musicPlayer.init();
            }

            
            this.mainContent.innerHTML = `
    <div class="profile-section">
        <div id="profileOuterContainer" class="profile-outer-container">
            <div id="profileInnerContainer" class="profile-inner-container">
                            <div id="profilePhoto" class="profile-photo">

                <img src=${blocktuneLogo} alt="Gated Logo" class="logo" style="width:150px;" />
                 </div>
                <div id="tipText" class="tip-text">Tip 1 STX</div>
                 

            </div>
        </div>
                <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>

        <div id="bnsv2NameDisplay" class="bns-name"></div>
    </div>
    <div class="page-wrapper">
        <div class="page-header">
        </div>
        <div class="page-description">Welcome to Blocktune. </br> </br> At Blocktune, we're pioneering a new way to release and experience music.Through the power of Stacks, we empower musicians to forge a new path for music distribution. </div>
        <div id="store-container"></div>
    </div>
    <img src=${gatedLogo} alt="Gated Logo" class="logo" id="showPopupButton1b" style="width:100px;padding:40px;cursor:pointer;"/>
`;

            // Add any CSS needed for movement
            const style = document.createElement('style');
            style.textContent = `
    .profile-outer-container {
        transform-origin: center;
        transition: transform 0.1s ease;
    }
`;
            document.head.appendChild(style);

            setTimeout(() => {
              try {
                if (!window.profilePhoto) {
                  window.profilePhoto = new ProfilePhoto();
                }
              } catch (error) {
                console.error('Error initializing profile photo:', error);
              }
            }, 0);
            renderStoreItems([musicData]);
       
              // Setup music event listeners after content is rendered
      this.setupMusicEventListeners();

          } catch (error) {
            console.error('Error rendering specific page:', error);
            this.mainContent.innerHTML = `
                <div class="error-message">
                    Failed to load content. Please try again later.
                </div>
            `;
          }
        }
      },
      page: {
        match: (parsedUrl) => {
          const hasStxAddress = !!parsedUrl.stxAddress;
          const hasPageTitle = !!parsedUrl.pageTitle;
          const notSpecificPage = parsedUrl.pageTitle?.toLowerCase() !== 'when-walls-break-test-1';
          console.log('Page match check:', {
            hasStxAddress,
            hasPageTitle,
            notSpecificPage,
            pageTitle: parsedUrl.pageTitle
          });
          return hasStxAddress && hasPageTitle && notSpecificPage;
        },
        render: (stxAddress, pageTitle) => this.renderUserPage(stxAddress, pageTitle)
      }
    };
  }

  checkExistingSession() {
    if (this.auth.userSession.isUserSignedIn()) {
      const userData = this.auth.userSession.loadUserData();
      const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
      this.currentStxAddress = userData.profile.stxAddress[network];
    }
  }

  parseUrl() {
    const host = window.location.hostname;
    const hash = window.location.hash.slice(1) || '/';
    const pathname = window.location.pathname;

    // Handle auth redirect
    if (pathname === '/auth') {
      if (this.auth?.userSession.isSignInPending()) {
        this.auth.handlePendingSignIn();
      }
      // Redirect to home after auth
      window.location.href = '/';
      return { stxAddress: null, pageTitle: null };
    }

    // Rest of your existing parseUrl code...
    const stxAddressMatch = host.match(/^([a-zA-Z0-9]+)\.(localhost|gated\.so)$/);
    const stxAddress = stxAddressMatch ? stxAddressMatch[1] : null;

    const pageTitle = hash.startsWith('/') ? hash.slice(1) : hash;

    return {
      stxAddress,
      pageTitle: pageTitle || null,
      isProduction: host.includes('gated.so')
    };
  }

  setupMusicEventListeners() {
    // Wait a bit for DOM to be fully rendered
    setTimeout(() => {
      const songItems = document.querySelectorAll('.song-list li');
      const playPauseBtn = document.getElementById('album-play-pause-btn');
      const prevBtn = document.getElementById('album-prev-btn');
      const nextBtn = document.getElementById('album-next-btn');
      const cardHeader = document.querySelector('.card-header');

      // Song list click handlers
      songItems.forEach((item, index) => {
        item.addEventListener('click', (e) => {
          // Don't trigger if clicking buy button
          if (e.target.classList.contains('mint-track-btn')) return;

          window.musicPlayer.setCurrentTrack(index);
          window.musicPlayer.loadTrack(index, musicData);
          window.musicPlayer.togglePlayPause();

          // Update UI
          songItems.forEach(i => i.classList.remove('active-track'));
          item.classList.add('active-track');

          // Update header image
          if (cardHeader) {
            cardHeader.style.backgroundImage = `url(${musicData.songs[index].imgUrl})`;
          }
        });
      });

      // Play/Pause button handler
      if (playPauseBtn) {
        playPauseBtn.addEventListener('click', () => {
          window.musicPlayer.togglePlayPause();
          const isPlaying = !window.musicPlayer.audioPlayer.paused;
          playPauseBtn.innerHTML = isPlaying ?
            '<i class="fa-solid fa-pause"></i>' :
            '<i class="fa-solid fa-play"></i>';
        });
      }

      // Previous/Next button handlers
      if (prevBtn) {
        prevBtn.addEventListener('click', () => {
          const newIndex = (window.musicPlayer.currentTrackIndex - 1 + musicData.songs.length) % musicData.songs.length;
          window.musicPlayer.setCurrentTrack(newIndex);
          window.musicPlayer.loadTrack(newIndex, musicData);
          window.musicPlayer.togglePlayPause();
        });
      }

      if (nextBtn) {
        nextBtn.addEventListener('click', () => {
          const newIndex = (window.musicPlayer.currentTrackIndex + 1) % musicData.songs.length;
          window.musicPlayer.setCurrentTrack(newIndex);
          window.musicPlayer.loadTrack(newIndex, musicData);
          window.musicPlayer.togglePlayPause();
        });
      }

      // Handle audio ended event
      window.musicPlayer.audioPlayer.addEventListener('ended', () => {
        const nextIndex = (window.musicPlayer.currentTrackIndex + 1) % musicData.songs.length;
        window.musicPlayer.setCurrentTrack(nextIndex);
        window.musicPlayer.loadTrack(nextIndex, musicData);
        window.musicPlayer.togglePlayPause();
      });
    }, 100); // Small delay to ensure DOM is ready
  }

  async handleRoute() {
    if (!this.mainContent) {
      console.error('Main content element not initialized!');
      return;
    }

    const parsedUrl = this.parseUrl();
    console.log('Parsed URL:', parsedUrl);

    this.mainContent.innerHTML = '';

    try {
      // First check for dashboard scenario
      if (parsedUrl.stxAddress && this.auth?.isSignedIn()) {
        const currentAddress = this.auth.getCurrentAddress()?.toLowerCase();
        const parsedAddress = parsedUrl.stxAddress?.toLowerCase();

        console.log('Dashboard check:', {
          currentAddress,
          parsedAddress,
          isSignedIn: this.auth.isSignedIn()
        });

        const isOwner = currentAddress === parsedAddress;

        if (isOwner && !parsedUrl.pageTitle) {
          this.renderAdminDashboard(parsedUrl.stxAddress);
          return;
        }
      }

      // Then check other routes
      const matchingRoute = Object.entries(this.routeHandlers)
        .find(([name, handler]) => {
          const matches = handler.match(parsedUrl);
          console.log(`Route ${name} matches:`, matches);
          return matches;
        });

      if (matchingRoute) {
        console.log('Found matching route:', matchingRoute[0]);
        await matchingRoute[1].render(parsedUrl.stxAddress, parsedUrl.pageTitle);
        return;
      }

      console.log('No matching route found for:', parsedUrl);
      this.renderErrorPage(new Error(`No page exists at this URL: ${window.location.pathname}${window.location.hash}`));
    } catch (error) {
      console.error('Error handling route:', error);
      this.renderErrorPage(error);
    }
  }
  // Update parseUrl to be more verbose
  parseUrl() {
    const host = window.location.hostname;
    const hash = window.location.hash.slice(1) || '/';
    const pathname = window.location.pathname;

    console.log('Parsing URL:', {
      host,
      hash,
      pathname,
      fullUrl: window.location.href
    });

    // Handle auth redirect
    if (pathname === '/auth') {
      if (this.auth?.userSession.isSignInPending()) {
        this.auth.handlePendingSignIn();
      }
      window.location.href = '/';
      return { stxAddress: null, pageTitle: null };
    }

    const stxAddressMatch = host.match(/^([a-zA-Z0-9]+)\.(localhost|gated\.so)$/);
    const stxAddress = stxAddressMatch ? stxAddressMatch[1] : null;

    const pageTitle = hash.startsWith('/') ? hash.slice(1) : hash;

    const parsed = {
      stxAddress,
      pageTitle: pageTitle || null,
      isProduction: host.includes('gated.so')
    };

    console.log('Parsed URL result:', parsed);
    return parsed;
  }

  renderErrorPage(error) {
    console.error('Rendering error page:', error);

    this.mainContent.innerHTML = `
        <div class="error-container">
            <div class="error-content">
                <h1>Oops! Something went wrong</h1>
                <p class="error-message">${this.escapeHtml(error.message || 'An unexpected error occurred')}</p>
                <div class="error-actions">
                    <button onclick="window.location.reload()" class="retry-btn">
                        Try Again
                    </button>
                    <a href="/" class="home-btn">
                        Go to Home
                    </a>
                </div>
            </div>
        </div>
    `;
  }




  setCurrentStxAddress(address) {
    this.currentStxAddress = address;
    if (this.mainContent) {
      this.handleRoute();
    }
  }

  navigate(path, stxAddress = null) {
    const baseUrl = stxAddress
      ? `${window.location.protocol}//${stxAddress}.${window.location.host}`
      : window.location.origin;

    const newUrl = `${baseUrl}${path}`;
    window.history.pushState({}, '', newUrl);
    this.handleRoute();
  }

  navigateToAdmin(stxAddress) {
    // Get current user data to check network
    const userData = this.auth.userSession.loadUserData();
    const connectedAddress = userData.profile.stxAddress;


    // Determine if we're connected to testnet or mainnet based on which address matches
    const isTestnet = connectedAddress.testnet.toLowerCase() === stxAddress.toLowerCase();

    console.log('Navigation details:', {
      stxAddress,
      connectedTestnet: connectedAddress.testnet,
      connectedMainnet: connectedAddress.mainnet,
      isTestnet
    });

    // Set domain based on network
    const domain = isTestnet ? 'localhost:3000' : 'gated.so';

    // Construct the full URL
    const targetUrl = `${window.location.protocol}//${stxAddress}.${domain}`;

    console.log('Navigating to:', targetUrl);

    // Perform the navigation
    window.location.href = targetUrl;
  }

  getPageUrl(stxAddress, pageTitle) {
    const domain = window.location.hostname.includes('gated.so') ? 'gated.so' : 'localhost';
    return `${window.location.protocol}//${stxAddress}.${domain}/#/${pageTitle}`;
  } 

  // You might also want to update the getDashboardUrl method to match:
  getDashboardUrl(stxAddress) {
    // Get current user data
    const userData = this.auth.userSession.loadUserData();
    const connectedAddress = userData.profile.stxAddress;

    // Check if we're on testnet or mainnet by comparing addresses
    const isTestnet = connectedAddress.testnet.toLowerCase() === stxAddress.toLowerCase();

    // Set appropriate domain
    const domain = isTestnet ? 'localhost:3000' : 'gated.so';

    console.log('Dashboard URL generation:', {
      stxAddress,
      testnetAddress: connectedAddress.testnet,
      mainnetAddress: connectedAddress.mainnet,
      isTestnet,
      domain
    });

    // Return the full URL
    return `${window.location.protocol}//${stxAddress}.${domain}`;
  }


 


  renderHomePage() {
    console.log('Starting renderHomePage');
    console.log('Auth object:', this.auth);

    const isSignedIn = this.auth.isSignedIn();
    const currentAddress = this.auth.getCurrentAddress();

  this.mainContent.innerHTML = `
        <div class="home-page">

              <img src=${gatedLogo} alt="Gated Logo" class="logo" style="width:400px;padding:40px;cursor:auto;"/>
            ${isSignedIn ? `
                <div id="wallet-container"></div>

                <button onclick="window.router.navigateToAdmin('${currentAddress}')" class="dashboard-btn">
                    Go to My Dashboard
                </button>
            ` : `
                <button id="connectWallet-button" onclick="window.connectWallet()" class="connect-btn">
                    Connect Wallet
                </button>
            `}
        </div>
    `;
    // Initialize and render the wallet dropdown
    // Add dropdown functionality after rendering
    this.setupWalletDropdown();

 
  }
   

 
  async loadPages(stxAddress) {
    const pagesList = document.getElementById('pages-list');
    pagesList.innerHTML = '<div class="loading">Searching blockchain for pages you own...</div>';



    try {
      const { pages } = await this.fetchNFTPages();  // Destructure pages from the result

     
  
      if (!pages || pages.length === 0) {
        pagesList.innerHTML = `
        <div class="empty-state">
          <p>No pages found. Create your first page!</p>
        </div>
      `;
        return;
      }
      

 
      pagesList.innerHTML = pages.map(page => `
      <div class="page-card">
        <div class="page-info">
          <h3>${page.title || `Page #${page.id}`}</h3>
          <p class="page-description">${page.description || 'No description available'}</p>
          <div class="page-metadata">
            <span class="status">${page.active ? 'Active' : 'Inactive'}</span>
          </div>
        </div>
        <div class="page-actions">
          <a href="#/${sanitizeUrl(page.title)}" target="_blank" class="view-btn">
            View Page
          </a>

          <button onclick="window.router.togglePageStatus(${page.id}, ${page.active})"
                  class="status-btn ${page.active ? 'active' : 'inactive'}">
            ${page.active ? 'Unpublish' : 'Publish'}
          </button>
        </div>
      </div>
    `).join('');

    } catch (error) {
      console.error('Error loading pages:', error);
      pagesList.innerHTML = `
      <div class="error-state">
        <p>Error loading your pages. Please try again.</p>
        <button onclick="window.router.loadPages('${stxAddress}')" class="retry-btn">
          Retry
        </button>
      </div>
    `;
    }
  }

  async fetchNFTPages() {
    try {
      const userData = this.auth.userSession.loadUserData();
      const userAddressTestnet = userData.profile.stxAddress.testnet;
      console.log('Fetching NFTs for testnet address:', userAddressTestnet);

      const nftHoldingsUrl = `https://stacks-node-api.testnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddressTestnet}&limit=50`;

      // Fetch NFT holdings with a delay to prevent spamming the API
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      const holdingsResponse = await fetch(nftHoldingsUrl);
      if (!holdingsResponse.ok) {
        console.error(`Error fetching holdings: ${holdingsResponse.statusText}`);
        return { pages: [], nfts: [] };
      }

      const holdingsData = await holdingsResponse.json();

      if (holdingsData.results && holdingsData.results.length > 0) {
        const sortedNFTs = holdingsData.results.sort((a, b) => b.block_height - a.block_height);
        console.log(`Found ${sortedNFTs.length} NFTs total`);

        const processedContent = [];
        for (const nft of sortedNFTs) {
          const [contractAddress, assetName] = nft.asset_identifier.split('::');
          const tokenId = nft.value?.repr ? parseInt(nft.value.repr.replace(/^u/, '')) : null;

          if (!tokenId) {
            console.log('Skipping NFT (likely BNS name) TESTNET:', nft.value);
            continue;
          }

          if (assetName === 'gated-page') {
            try {
              const options = {
                contractAddress: 'ST1ZCYG0D3HCK2F7SY8VH9ZREB0JWCBSAPEQCTM17',
                contractName: 'shivering-blush-macaw',
                functionName: 'get-page',
                functionArgs: [uintCV(tokenId)],
                network: STACKS_TESTNET,
                senderAddress: userAddressTestnet
              };

              // Add delay between requests to avoid rate-limiting
              await delay(200); // Adjust delay as needed

              const result = await fetchCallReadOnlyFunction(options);
              if (result && result.value) {
                const pageData = result.value.value;
                const isActive = typeof pageData.active === 'boolean'
                  ? pageData.active
                  : pageData.active?.type === 'true' ||
                  pageData.active?.value === true ||
                  pageData.active?.type === 3;

                const page = {
                  type: 'page',
                  id: tokenId,
                  owner: pageData.owner.value,
                  active: isActive,
                  title: pageData.title.value,
                  description: pageData.description.value,
                  metadataUri: pageData['metadata-uri']?.value.data || '',
                  url: `https://${userAddressTestnet}.gated.so/#/${pageData.title?.data || tokenId}`,
                  blockHeight: nft.block_height
                };

                console.log('Processed page:', page);
                processedContent.push(page);
              }
            } catch (pageError) {
              console.error('Error fetching page data TESTNET:', pageError);
            }
          }
        }

        this.pages = processedContent.filter(item => item && item.type === 'page');
        console.log(`Found ${this.pages.length} valid pages`);
        return {
          pages: this.pages,
          nfts: []
        };
      }

      console.log('No NFTs found');
      return {
        pages: [],
        nfts: []
      };

    } catch (error) {
      console.error('Error in fetchUserContent Testnet:', error);
      return {
        pages: [],
        nfts: []
      };
    }
  }


  // Helper method to format addresses
  formatAddress(address) {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  }

  // Add these navigation methods
  viewNFTPage(contractAddress, tokenId) {
    // Implement view page navigation
    window.open(`/${contractAddress}/${tokenId}`, '_blank');
  }

  editNFTPage(contractAddress, tokenId) {
    // Implement edit page navigation
    window.location.href = `/edit/${contractAddress}/${tokenId}`;
  }

  // Add this new method to handle dropdown setup
  setupWalletDropdown() {
    console.log('Setting up wallet dropdown');

    const dropdownBtn = document.getElementById('wallet-dropdown-btn');
    const dropdownContent = document.getElementById('wallet-dropdown-content');

    if (!dropdownBtn || !dropdownContent) {
      console.error('Dropdown elements not found');
      return;
    }

    // Toggle dropdown on button click
    dropdownBtn.addEventListener('click', (e) => {
      e.stopPropagation();
      dropdownContent.classList.toggle('show');
      console.log('Dropdown toggled');
    });

    // Close dropdown when clicking outside
    document.addEventListener('click', (e) => {
      if (!dropdownBtn.contains(e.target)) {
        dropdownContent.classList.remove('show');
      }
    });

    // Prevent dropdown from closing when clicking inside content
    dropdownContent.addEventListener('click', (e) => {
      e.stopPropagation();
    });

  // Add dropdown functionality if signed in
  if (!isSignedIn) {
    const dropdownBtn = document.getElementById('wallet-dropdown-btn');
    const dropdownContent = document.getElementById('wallet-dropdown-content');

    dropdownBtn.addEventListener('click', (e) => {
      e.stopPropagation();
      dropdownContent.classList.toggle('show');
    });

    document.addEventListener('click', (e) => {
      if (!e.target.matches('.wallet-btn')) {
        dropdownContent.classList.remove('show');
      }
    });
  }
  }

  renderClaimPage(stxAddress) {
    // First check if session is still valid
    if (!this.auth.userSession.isUserSignedIn() && this.auth.isSignedIn()) {
      console.log('Session expired, triggering sign out');
      this.auth.handleSignOut();
      return;
    }

    const isSignedIn = this.auth.userSession.isUserSignedIn();
    let currentAddress = null;

    if (isSignedIn) {
      const userData = this.auth.userSession.loadUserData();
      // Get address based on network (testnet or mainnet)
      const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
      currentAddress = userData.profile.stxAddress[network];
    }

    // Normalize addresses to lowercase for comparison
    const normalizedPageAddress = stxAddress?.toLowerCase();
    const normalizedCurrentAddress = currentAddress?.toLowerCase();
    const matchesPage = normalizedCurrentAddress === normalizedPageAddress;

    console.log('Claim page state:', {
      isSignedIn,
      currentAddress,
      stxAddress,
      normalizedCurrentAddress,
      normalizedPageAddress,
      matchesPage,
      network: window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet'
    });

    let contentToShow;

    if (!isSignedIn) {
      contentToShow = `
            <div class="connect-prompt">
                <p>Connect your wallet to claim this page</p>
                <button id="connectWallet-button" onclick="window.connectWallet()">
                    Connect Wallet
                </button>
            </div>
        `;
    } else if (matchesPage) {
      const dashboardUrl = this.getDashboardUrl(stxAddress);
      contentToShow = `
    <div class="access-message">
     <p>✅ Access Granted</p>
      <button 
        onclick="showSuccessAndRedirect('${dashboardUrl}')" 
        class="btn btn-primary"
      >
        Go to Dashboard
      </button>
      <button 
        onclick="window.disconnectWallet()" 
        class="btn btn-secondary">
        Sign Out
      </button>
    </div>
  `;
      
      window.showSuccessAndRedirect = (url) => {
        const container = document.querySelector('.access-message');
        container.innerHTML = `
      <div class="alert-check success-transition">
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" width="20" height="20">
          <path d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p>Access granted! Redirecting to dashboard...</p>
      </div>
    `;

        // Redirect after a short delay to show the success message
        setTimeout(() => {
          window.location.href = url;
        }, 1500);
      };
    
    } else {
      contentToShow = `
            <div class="error-message">
                <p>❌ Your wallet is connected to a different address:</p>
                 <p class="suggestion">
                    Perhaps you were looking for

                  <a href="https://${currentAddress.toLowerCase()}.gated.so">
                ${currentAddress.toLowerCase()}.gated.so
            </a>

                </p>
                <div class="action-buttons">
                    <button onclick="window.connectWallet(true)" class="primary-btn">
                        Switch Account
                    </button>
                </div>
            </div>
        `;
    }

    const styles = `
  .claim-container {
    max-width: 32rem;
    margin: 0 auto;
    padding: 1.5rem;
  }

  .claim-card {
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
    border: 1px solid hsl(240 5.9% 90%);
  }

  .card-header {
    padding: 20px;
  }

  .card-title {
    padding:20px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    margin: 0;
    color: hsl(240 3.8% 46.1%);
  }

  .card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .text-muted {
    color: hsl(240 3.8% 46.1%);
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .address-code {
    font-family: ui-monospace, monospace;
    font-size: 0.875rem;
    background: hsl(240 4.8% 95.9%);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    display: inline-block;
    color: hsl(240 5.9% 10%);
  }

  @media (max-width: 640px) {
    .claim-container {
      padding: 1rem;
    }
  }
`;

    // Add the styles to the document
    if (!document.getElementById('claim-page-styles')) {
      const styleSheet = document.createElement('style');
      styleSheet.id = 'claim-page-styles';
      styleSheet.textContent = styles;
      document.head.appendChild(styleSheet);
    }
    this.mainContent.innerHTML = `
  <div class="claim-container">
    <div class="claim-card">
        <h1 class="card-title">Claim This Page</h1>
      <div class="card-content">
        <div class="address-section">
          <p class="text-muted">This page belongs to STX address:</p>
          <code class="address-code">${stxAddress}</code>
        </div>
        ${contentToShow}
      </div>
    </div>
  </div>
    <img src=${gatedLogo} alt="Gated Logo" class="logo" />
`;
  }

  
  hideCreatePageModal() {
    const modal = document.getElementById('create-page-modal');
    if (modal) {
      modal.classList.add('hidden');
      const form = document.getElementById('create-page-form');
      if (form) form.reset();
    }
  }

  showCreatePageModal() {
    // First, inject the modal HTML if it doesn't exist
    if (!document.getElementById('create-page-modal')) {
      const modalHTML = `
      <div id="create-page-modal" class="modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"  style="display: none;">
        <div class="modal-content bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          <h3>Create New Page</h3>
          <form id="create-page-form" class="space-y-4">
            <div>
              <div class="create-page-title">Page Title</div>
              <input 
                type="text" 
                name="title" 
                id="title"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Enter page title" 
                required
              >
            </div>
            <div>
                <div class="create-page-description">Description</div>              <textarea
                name="description" 
                id="description"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 placeholder:font-sans"
                rows="3"
                placeholder="Enter page description"
                required
              ></textarea>
            </div>
            <div class="create-page-buttons">
              <button 
                type="button" 
                class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                onclick="window.app.hideCreatePageModal()"
              >
                Cancel
              </button>
              <button 
                type="submit" 
                class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                Create Page
              </button>
            </div>
          </form>
        </div>
      </div>
    `;
      document.body.insertAdjacentHTML('beforeend', modalHTML);
    }
    // Now we can safely access the modal elements
    const modal = document.getElementById('create-page-modal');
    const form = document.getElementById('create-page-form');
    const createBtn = document.querySelector('.create-btn');

    // Setup create button click handler
    if (createBtn) {
      createBtn.addEventListener('click', () => {
        modal.style.display= 'flex';
        modal.querySelector('input[name="title"]').focus();
      });
    }

    // Setup form submission
  if (form) {
    form.addEventListener('submit', async (e) => {
      e.preventDefault();

      try {
        const stxAddress = this.auth.userSession.loadUserData().profile.stxAddress.mainnet;
        const title = form.querySelector('input[name="title"]').value.trim();
        const description = form.querySelector('textarea[name="description"]').value.trim();
        const slug = title.toLowerCase().replace(/[^a-z0-9]+/g, '-');

        if (!title || !description) {
          throw new Error('Please fill in all fields');
        }

        // Store form data in localStorage
        localStorage.setItem('pendingPage', JSON.stringify({
          title,
          description
        }));

        // Hide the create page modal
        modal.style.display = 'none';
        form.reset();

        // Show confirmation modal before proceeding with transaction
        this.showPublishConfirmation(title, description, slug, stxAddress);
      } catch (error) {
        alert(error.message);
      }
    });
  }

    // Setup click-outside-to-close
    modal.addEventListener('click', (e) => {
      if (e.target === modal) {
        modal.classList.add('hidden');
        form.reset();
      }
    });

    // Setup escape key to close
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
        modal.classList.add('hidden');
        form.reset();
      }
    });
  }
  
  hideCreatePageModal() {
    const modal = document.getElementById('create-page-modal');
    const form = document.getElementById('create-page-form');
    if (modal) {
      modal.style.display = 'none';
      if (form) form.reset();
    }
  }


  showPublishConfirmation(title, description, slug, stxAddress) {
    const confirmModal = document.createElement('div');
    confirmModal.id = 'confirm-modal';
    confirmModal.className = 'modal';

    confirmModal.innerHTML = `
    <div class="modal-content">
      <h3>Confirm Page Creation</h3>
      <p>You are about to create a new page:</p>
      <p><strong>Title:</strong> ${title}</p>
      <p><strong>Description:</strong> ${description}</p>
      <p><strong>URL:</strong> ${stxAddress}.gated.so/#/${slug}</p>
      <div class="button-group">
        <button type="button" class="cancel-btn">Cancel</button>
        <button type="button" class="confirm-btn">Create Page</button>
      </div>
    </div>
  `;

    document.body.appendChild(confirmModal);

    // Setup confirmation handlers
    const cancelBtn = confirmModal.querySelector('.cancel-btn');
    const confirmBtn = confirmModal.querySelector('.confirm-btn');

    const closeConfirmModal = () => {
      document.body.removeChild(confirmModal);
    };

    cancelBtn.addEventListener('click', closeConfirmModal);

    // Handle confirmation and contract call
    confirmBtn.addEventListener('click', async () => {
      try {
        const userSession = new UserSession();
        const userData = userSession.loadUserData();
        const senderAddress = userData.profile.stxAddress.testnet;
        const contract = 'ST1ZCYG0D3HCK2F7SY8VH9ZREB0JWCBSAPEQCTM17.shivering-blush-macaw';
        const metadataUri = `ipfs://${slug}`;
        const gatedFee = 1000000;

        // Prepare contract call
        const functionArgs = [
          stringAsciiCV(title),
          stringAsciiCV(description),
          stringAsciiCV(metadataUri)
        ];

        const postConditions = [
          Pc.principal(senderAddress).willSendEq(gatedFee).ustx()
        ];

        const options = {
          network: STACKS_TESTNET,
          contractAddress: contract.split('.')[0],
          contractName: contract.split('.')[1],
          functionName: 'add-page',
          functionArgs: functionArgs,
          postConditions: postConditions,
          onFinish: data => {
            console.log('Transaction:', data);
            localStorage.removeItem('pendingPage');
            window.location.href = `/#/${slug}`;
          }
        };

        // Make the contract call
        await openContractCall(options);

      } catch (error) {
        console.error('Error creating page:', error);
        alert(error.message);
      } finally {
        closeConfirmModal();
      }
    });

    // Close on click outside
    confirmModal.addEventListener('click', (e) => {
      if (e.target === confirmModal) {
        closeConfirmModal();
      }
    });
  }


  renderAdminDashboard(stxAddress) {
    console.log('Rendering admin dashboard for:', stxAddress);


    this.mainContent.innerHTML = `
        <div class="admin-dashboard">
            <header class="dashboard-header">
              <div class="dashboard-header-text-container">
                <div class="dashboard-header-text">
                <a href="https://${stxAddress}.gated.so" style="text-decoration: none;">
                   <div class="dashboard-header-text">
                        Dashboard
                      </div>
</a>
                 </div>
                 <div class="dashboard-header-text-subnav">
                 ${this.auth.formatAccountNumber(stxAddress)}
                 </div>
                </div> 
                <div class="wallet-dropdown">
                   
                       
                    <div id="wallet-dropdown-content" class="wallet-dropdown-content">
                        <button onclick="window.disconnectWallet()" class="dropdown-item">Switch Accounts</button>
                    </div>
                </div>
            </header>

            <div class="dashboard-content">
                <section class="sidebar">
                  <div class="sidebar-header">
                  Menu
                  </div>
                  <div class="sidebar-list">
                    <div class="item-pages">Pages</div>
                    <div class="item-media">Media</div>
                  </div>
                </section>

                <section class="pages-section">
                    <div class="section-header">
                        <div class="section-header-text">
                          <h2>Your Pages</h2>
                          <div class="section-header-subtitle">Pages you own. Secured by blockchain.</div>
                        </div>
                          <button class="create-btn">Create New Page</button>
                    </div>
                    <div id="pages-list" class="pages-list">
                        Loading pages from Blockchain...
                    </div>
                </section>
            </div>
        </div>
    `;


    // Add dropdown functionality after rendering
    this.setupWalletDropdown();

    // Initialize other dashboard functionality
    this.loadPages(stxAddress);
    this.showCreatePageModal();

  }

  // Add this new method to handle dropdown setup
  setupWalletDropdown() {
    console.log('Setting up wallet dropdown');

    const dropdownBtn = document.getElementById('wallet-dropdown-btn');
    const dropdownContent = document.getElementById('wallet-dropdown-content');

    if (!dropdownBtn || !dropdownContent) {
      console.error('Dropdown elements not found');
      return;
    }

    // Toggle dropdown on button click
    dropdownBtn.addEventListener('click', (e) => {
      e.stopPropagation();
      dropdownContent.classList.toggle('show');
      console.log('Dropdown toggled');
    });

    // Close dropdown when clicking outside
    document.addEventListener('click', (e) => {
      if (!dropdownBtn.contains(e.target)) {
        dropdownContent.classList.remove('show');
      }
    });

    // Prevent dropdown from closing when clicking inside content
    dropdownContent.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  }
  
  async loadUserPages(stxAddress) {
    try {
      const pagesList = document.getElementById('pages-list');
      const pages = await window.fetchPagesForAddress(stxAddress);

      if (pages.length === 0) {
        pagesList.innerHTML = `
        <div class="empty-state">
          <p>You haven't created any pages yet.</p>
          <p>Click "Create New Page" to get started.</p>
        </div>
      `;
        return;
      }

      pagesList.innerHTML = pages.map(page => {
        const pageUrl = `${window.location.protocol}//${stxAddress}.${window.location.host}/#/${page.title}`;

        return `
          <div class="page-card">
            <div class="page-header">
              <h3 class="page-title">${page.title}</h3>
              <div class="url-container flex items-center gap-2">
                <p class="page-url truncate">${pageUrl}</p>
                <button
                  onclick="copyPageUrl('${pageUrl}')" 
                  class="copy-btn flex items-center p-2 hover:bg-gray-100 rounded"
                  title="Copy URL"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="page-actions">
              <a href="${page.title}" class="action-btn btn-primary">View Page</a>
            </div>
          </div>
        `;
      }).join('');

      // Add the copyPageUrl function to window
      window.copyPageUrl = async (url) => {
        try {
          await navigator.clipboard.writeText(url);
          const btn = event.target.closest('.copy-btn');

          // Show feedback
          const originalHTML = btn.innerHTML;
          btn.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          `;

          // Reset after 2 seconds
          setTimeout(() => {
            btn.innerHTML = originalHTML;
          }, 2000);
        } catch (err) {
          console.error('Failed to copy:', err);
        }
      };

    } catch (error) {
      console.error('Error loading user pages:', error);
      const pagesList = document.getElementById('pages-list');
      pagesList.innerHTML = `
        <div class="empty-state">
          <p>Error loading pages. Please try again later.</p>
        </div>
      `;
    }
  }


  renderUserPage(stxAddress, pageTitle) {
    try {
      this.mainContent.innerHTML = `
            <div class="page-wrapper">
                <h1>${pageTitle}</h1>
                <div id="store-container"></div>
                <p>This page is owned by </p> <code class="address-code">${stxAddress}</code>
            </div>
        `;

      const storeContainer = document.getElementById('store-container');

      // Assuming `storeItems[0]` is the album to display
      // const musicPlayerCard = createMusicPlayerCard(storeItems[0]);
      // storeContainer.appendChild(musicPlayerCard);

      // Initialize audio controls here
      this.initializeAudioControls();

    } catch (error) {
      console.error('Error rendering user page:', error);
      this.mainContent.innerHTML = `
            <div class="error-message">Failed to load page content. Please try again later.</div>
        `;
    }
  }

  initializeAudioControls() {
    const playPauseBtn = document.getElementById('play-pause-btn');
    const prevBtn = document.getElementById('prev-btn');
    const nextBtn = document.getElementById('next-btn');

    playPauseBtn?.addEventListener('click', togglePlayPause);
    prevBtn?.addEventListener('click', playPreviousTrack);
    nextBtn?.addEventListener('click', playNextTrack);
  }

  // Add this helper method if you don't already have it
  escapeHtml(unsafe) {
    if (!unsafe) return '';
    return unsafe
      .toString()
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  async loadPageContent(stxAddress, pageTitle) {
    try {
      const nftContent = document.getElementById('nft-content');
      const content = await window.fetchPageContent(stxAddress, pageTitle);

      nftContent.innerHTML = `
        <div class="nft-card">
          <h2>${content.nftName}</h2>
          <p>${content.description}</p>
          <button onclick="window.mintNFT('${content.id}')">
            Mint NFT (${content.price} STX)
          </button>
        </div>
      `;
    } catch (error) {
      console.error('Error loading page content:', error);
    }
  }
}

// Add the copy function
async function copyPageUrl(url) {
  try {
    await navigator.clipboard.writeText(url);
    const btn = event.target.closest('.copy-btn');

    // Show feedback
    const originalHTML = btn.innerHTML;
    btn.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    `;

    // Reset after 2 seconds
    setTimeout(() => {
      btn.innerHTML = originalHTML;
    }, 2000);
  } catch (err) {
    console.error('Failed to copy:', err);
  }
}

// Initialize router
window.router = new Router();

// Export for module usage
export default Router;